import React, { Component } from 'react';

import LazyLoader from './LazyLoader'
import List from './List'
import AuthManager from '../../../utils/AuthManager'


export default class LazyLoadingList extends Component  {

  constructor(props){
    super(props);
    this.state = {
      endpoint: props.endpoint,
      items: [],
      isInitialLoading: true,
      isLoadingMore: false,
    }
    this.lazyLoader = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  refresh(){
    this.lazyLoader.current._refresh()
  }

  getLazyLoader(){
    return this.lazyLoader.current
  }

  updateItem(item, index){
    let { items } = this.state
    items[index] = item
    this.lazyLoader.current.setState({ items })
  }

  addItem(item){
    let { items } = this.state
    items.unshift(item)
    this.lazyLoader.current.setState({ items })
  }

  removeItem(itemToRemove){
    let items = this.state.items.filter(item => item.id !== itemToRemove.id)
    this.lazyLoader.current.setState({ items })
    this.setState({ items })
  }

  _renderList() {
    let { items, isInitialLoading } = this.state;

    if (isInitialLoading) {
      return this.props.renderInitialLoading();
    } else if (items.length === 0) {
      return this.props.renderNoContent();
    }

    return (
      <>
        <List
          items={items}
          grow={this.props.grow}
          renderItem={(item, index) => this.props.renderItem(item, index)}
          loadMore={() => {
            if (
              this.lazyLoader.current &&
              this.lazyLoader.current.state.hasMore
            ) {
              this.lazyLoader.current._loadMore();
              this.setState({ isLoadingMore: true });
            }
          }}
        />
        {this.state.isLoadingMore && this.props.renderLoadingMore()}
      </>
    );
  }

  render(){
    let {
      endpoint,
      items
    } = this.state

    return (
      <LazyLoader
        ref={this.lazyLoader}
        endpoint={endpoint}
        params={this.props.params}
        headers={AuthManager.getHeaders()}
        onEndReached="loadMore"
        onItemsUpdated={(items) => {
          this.props.onItemUpdated(items);
          this.setState({
            items,
            isInitialLoading: false,
            isLoadingMore: false,
          });
        }}
        onRefresh={(items) =>
          this.setState({
            items: [],
            isInitialLoading: true,
            isLoadingMore: false,
          })
        }
      >
        {this._renderList()}
      </LazyLoader>
    )
  }


}
