import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Notify from "../../../utils/Notify"
import FileUploader from "../common/FileUploader";
import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";
import Dropzone from "../common/Dropzone";

export default class UploadTransactions extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      loading: false,
      error: null,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      title: props.title,
      integration: props.integration,
      show: props.show,
      files: []
    }
  }

  _uploadFiles(){
    let {
      files,
      integration
    } = this.state

    if(!files){
      Notify.error("Please select the file you wish to upload.")
    }

    let data = {
      file: files[0],
      integration: integration.id
    }

    this.setState({loading: true})

    Backend.uploadTransactions(data).then(() => {
      this.setState({loading: false})
      Notify.success("Transactions Uploaded Successfully")
      this.props.onSuccess()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      show,
      title,
      body,
      files,
      loading
    } = this.state


    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName={'modal-dialog-centered modal-md'}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload TAX Report</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">

            <p className="text-muted">
              Upload your TAX Report to have your sales filed automatically.
              Your TAX Report can be downloaded from you Amazon Dashboard from
              the Reports section and is often referred to as ‘Amazon VAT Transactions Report’
            </p>

            <Dropzone
              accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              endpoint={window.Api.Files}
              type={"document"}
              onUploaded={file => {
                files.push(file.id)
                this.setState({ files })
              }}
              onUploadStarted={() => {
                this.setState({ loading: true })
              }}
              onUploadFinished={() => {
                this.setState({ loading: false })
              }}
              onRemoved={file => {
                const index = files.indexOf(file.id)
                if(index > -1) {
                  files.splice(index, 1)
                  this.setState({files})
                }
              }}
            />

          </div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className={`btn btn-light-${this.props.theme} font-weight-bold`}
            onClick={() => this.props.onHide()}
          >
            {this.props.dismissTitleText}
          </button>

          <button
            type="button"
            className={`btn btn-${this.props.theme} font-weight-bold`}
            disabled={loading}
            onClick={() => this._uploadFiles()}
          >
            {this.props.confirmTitleText}
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

UploadTransactions.defaultProps = {
  confirmTitleText: "Upload",
  theme: "primary",
  dismissTitleText: "Cancel"
}
