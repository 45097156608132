import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Notify from "../../../utils/Notify"

export default class ConfirmationModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      loading: false,
      error: null,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      title: props.title,
      show: props.show,
    }
  }

  render() {
    let {
      show,
      title,
      body,
      loading
    } = this.state

    return (
      <Modal
        show={show}
        dialogClassName={'modal-dialog-centered modal-md'}
        onHide={() => this.props.onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        { body &&
          <Modal.Body>
            {body}
          </Modal.Body>
        }
        <Modal.Footer>

          <button type="button" className={`btn btn-light-${this.props.theme} font-weight-bold`}
                  onClick={() => this.props.onHide()}
          >
            {this.props.dismissTitleText}
          </button>

          {
            this.props.confirmTitleText &&
            <button type="button" disabled={loading} className={`btn btn-${this.props.deleteTheme} font-weight-bold`}
                    onClick={() => this.props.onConfirm()}
            >
              {this.props.confirmTitleText}
            </button>
          }


        </Modal.Footer>
      </Modal>
    )
  }
}

ConfirmationModal.defaultProps = {
  confirmTitleText: "",
  theme: "primary",
  deleteTheme: "primary",
  dismissTitleText: "Dismiss"
}
