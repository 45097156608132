import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {};

window.Api.User.Base                       = window.Api.Base + "/user"

window.Api.User.Login                      = window.Api.User.Base + "/login"
window.Api.User.Logout                     = window.Api.User.Base + "/logout"
window.Api.User.Info                       = window.Api.User.Base + "/info"
window.Api.User.RequestResetPassword       = window.Api.User.Base + "/request-reset-password"
window.Api.User.ResetPassword              = window.Api.User.Base + "/reset-password"
window.Api.User.RefreshToken               = window.Api.User.Base + "/refresh-token"

window.Api.Register                        = window.Api.Base + "/companies"

window.Api.Companies                       = window.Api.Base + "/companies"
window.Api.CompanyInfo                     = window.Api.Base + "/company-info"
window.Api.CompanyMembers                  = window.Api.Base + "/company-members"

window.Api.TaxInfo                         = window.Api.Base + "/tax-info"
window.Api.Files                           = window.Api.Base + "/files"
window.Api.Integrations                    = window.Api.Base + "/integrations"

window.Api.Records                         = window.Api.Base + "/records"
window.Api.Documents                       = window.Api.Base + "/documents"
window.Api.LineItems                       = window.Api.Base + "/line-items"
window.Api.Filings                         = window.Api.Base + "/filings"
window.Api.Roles                           = window.Api.Base + "/roles"
window.Api.Conversations                   = window.Api.Base + "/conversations"
window.Api.Messages                        = window.Api.Base + "/messages"
window.Api.Ingestions                      = window.Api.Base + "/ingestions"
window.Api.Products                        = window.Api.Base + "/products"
window.Api.Logs                            = window.Api.Base + "/logs"
window.Api.Entities                        = window.Api.Base + "/entities"
window.Api.Payments                        = window.Api.Base + "/payments"
window.Api.TaxNumbers                      = window.Api.Base + "/tax-numbers"
window.Api.Currencies                      = window.Api.Base + "/currencies"
window.Api.Reports                         = window.Api.Base + "/reports"
window.Api.Packages                        = window.Api.Base + "/packages"
window.Api.Invoices                        = window.Api.Base + "/invoices"
