import React from 'react'

import BaseTable from './BaseTable'

import moment from 'moment'

import RoleModal from '../modals/Role'
import ConfirmationModal from '../modals/Confirmation'

import EditCell from './cells/EditCell'
import DeleteCell from './cells/DeleteCell'

import Notify from '../../../utils/Notify'
import Backend from '../../../utils/Backend'
import Permissions from "../../../utils/Permissions";
import Tooltip from "@material-ui/core/Tooltip";
import General from "../../../utils/General";

export default class RolesTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      live: props.live,
    }

    this.table = React.createRef()
  }

  _getColumns() {
    return [
      {
        Header: 'Created At',
        id: 'created_at',
        width: 150,
        accessor: (role) => {
          return moment(role.created_at).format('DD MMM YYYY')
        },
      },
      {
        Header: 'Name',
        id: 'name',
        width: 150,
        accessor: (role) => role.name,
      },
      {
        Header: 'Permissions',
        id: 'permissions',
        sortable: false,
        exportable: false,
        Cell: (rowInfo) => {
          let role = rowInfo.original

          let permissions = []
          Permissions.OPTIONS.map(permission => {
            let permissionString = role.permissions[permission.key].join(',')
            let selectedPermission = permission.options.find((option) => {
              return option.value === permissionString
            })
            if(selectedPermission){
              permissions.push({
                name: permission.label,
                color: permission.color,
                key: selectedPermission.label
              })
            }
          })
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start">
                {
                  permissions.map(permission => (
                    <>
                      <Tooltip title={permission.key} placement={"top"} arrow>
                        <span className={`badge ${permission.color} badge-sm fs-base ms-3`}>
                          {permission.name}
                        </span>
                      </Tooltip>
                    </>
                  ))
                }
              </div>
            </div>
          )
        },
      },
      {
        Header: 'Actions',
        id: 'status',
        sortable: false,
        exportable: false,
        width: 100,
        Cell: (rowInfo) => {
          let role = rowInfo.original
          return (
            <>
              <div className={"my-auto float-end"}>
                { !role.admin &&
                  <>
                    {
                      Permissions.hasEditPermission(Permissions.ROLES) &&
                      <EditCell
                        tooltip={"Edit"}
                        onEditClicked={() => {
                          this.setState({
                            showRoleModal: true,
                            role,
                          })
                        }}
                      />
                    }

                    { (Permissions.hasDeletePermission(Permissions.ROLES) && !role.deleted_at) &&
                      <DeleteCell
                        tooltip={"Delete"}
                        iconClass={"la-trash"}
                        onDeleteClicked={() => {
                          this.setState({
                            showConfirmationModal: true,
                            role,
                          })
                        }}
                      />
                    }
                  </>
                }
              </div>
            </>
          )
        },
      },
    ]
  }

  _deleteRole() {
    let { role } = this.state

    this.setState({loading: true})

    Backend.deleteRole(role)
      .then((role) => {
        Notify.success('Role has been deleted')
        this.table.current.refresh()
        this.setState({
          loading: false,
          role: null,
          showConfirmationModal: false,
        })
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _renderToolBar() {
    return (
      <>
        {
          Permissions.hasCreatePermission(Permissions.ROLES) &&
          <a
            className="btn btn-primary ms-5 font-weight-bolder"
            onClick={() => {
              this.setState({
                showRoleModal: true,
              })
            }}
            style={{ height: "45px" }}
          >
          <span className="svg-icon svg-icon-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect
                  fill="#000000"
                  x="4"
                  y="11"
                  width="16"
                  height="2"
                  rx="1"
                ></rect>
                <rect
                  fill="#000000"
                  opacity="0.3"
                  transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                  x="4"
                  y="11"
                  width="16"
                  height="2"
                  rx="1"
                ></rect>
              </g>
            </svg>
          </span>
            Add Role
          </a>
        }
      </>
    )
  }

  _renderRoleModal() {
    let { role, showRoleModal } = this.state

    return (
      <RoleModal
        show={showRoleModal}
        role={General.clone(role)}
        onHide={() => this.setState({ showRoleModal: false, role: null })}
        onAdded={(role) => {
          this.table.current.refresh()
          this.setState({ showRoleModal: false, role: null })
        }}
      />
    )
  }

  _renderConfirmationModal() {
    let { showConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure?"}
        body={" This action can't be undone."}
        confirmTitleText={"Delete"}
        deleteTheme={"danger"}
        loading={this.state.loading}
        onConfirm={() => {
          this._deleteRole()
        }}
        onHide={() => {
          this.setState({
            role: null,
            showConfirmationModal: false,
          })
        }}
      />
    )
  }

  render() {
    let { live } = this.state
    const columns = this._getColumns()

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Roles}`}
          noDataMessage={'No roles found'}
          title={this.props.title}
          columns={columns}
          objects={"active"}
          showExport={false}
          defaultSorted={[
            {
              id: 'created_at',
              desc: true,
            },
          ]}
          showSearch={!this.props.latestResults}
          showPaginationBottom={!this.props.latestResults}
          renderToolbar={() => this._renderToolBar()}
        />
        {this._renderRoleModal()}
        {this._renderConfirmationModal()}
      </div>
    )
  }
}

RolesTable.defaultProps = {
  latestResults: false,
}
