import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
// import Edit from "./cells/Edit";
// import Delete from "./cells/Delete";
// import Pill from "./cells/Pill";
// import CompanyMembers from "./CompanyMembers";
import View from "./cells/ViewCell";

import General from "../../../utils/General";
import Currency from "../../../utils/Currency";
import Document from "../modals/Document";
import UploadPurchasesModal from "../modals/UploadPurchases";
import Permissions from "../../../utils/Permissions";
import ConfirmationModal from "../modals/Confirmation";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import DeleteCell from "./cells/DeleteCell";

const STATUS_FILTER = {
  name: {
    api: 'status',
    label: 'Status',
  },
  values: [
    {
      label: "Pending",
      value: "pending"
    },
    {
      label: "Processing",
      value: "processing"
    },
    {
      label: "Processed",
      value: "processed"
    },
    {
      label: "Rejected",
      value: "rejected"
    }
  ]
}

const FILTERS = [STATUS_FILTER]

class Documents extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      filters: FILTERS,
    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Created At',
        id: 'created_at',
        width: 200,
        Cell: rowInfo => {
          let document = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <span class="text-muted fw-bold text-muted d-block fs-7">{moment(document.created_at).format('DD MMM YYYY')}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Name',
        id: 'file.name',
        width: 500,
        Cell: rowInfo => {
          let document = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-start flex-column">
                <span
                  className="text-muted fw-bold text-muted d-block fs-7">
                  {document.file.name}
                </span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Status',
        id: 'status',
        width: 150,
        Cell: rowInfo => {
          let document = rowInfo.original
          let className = 'badge-light-dark'
          if(document.status === "processing"){
            className = 'badge-light-primary'
          }else if(document.status === "processing"){
            className = 'badge-light-primary'
          }else if(document.status === "processed"){
            className = 'badge-light-success'
          }else if(document.status === "rejected"){
            className = 'badge-light-danger'
          }
          return (
            <span class={`badge ${className} badge-sm fs-base m-lg-auto`}>
              { document.status?.toUpperCase() }
            </span>
          )
        }
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let document = rowInfo.original
          return (
            <div className={"my-auto float-end"}>
              <View
                tooltip={"View"}
                onViewClicked={() => {
                  this.setState({
                    selectedDocument: document,
                    showDocumentModal: true
                  })
                }}
              />
            </div>
          )
        }
      }
    ]

    return columns
  }

  _renderToolBar() {
    return (
      <>
        {
          Permissions.hasCreatePermission(Permissions.DOCUMENTS) &&
          <a
            className="btn btn-primary ms-5 font-weight-bolder"
            onClick={() => {
              this.setState({
                showPurchasesModal: true,
              })
            }}
            style={{ height: "45px" }}
          >
            <span className="svg-icon svg-icon-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect
                    fill="#000000"
                    x="4"
                    y="11"
                    width="16"
                    height="2"
                    rx="1"
                  ></rect>
                  <rect
                    fill="#000000"
                    opacity="0.3"
                    transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                    x="4"
                    y="11"
                    width="16"
                    height="2"
                    rx="1"
                  ></rect>
                </g>
              </svg>
            </span>
            Upload Purchases
          </a>
        }
      </>
    )
  }

  render() {
    let {
      showPurchasesModal,
      showDocumentModal,
      selectedDocument,
      filters
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Documents}`}
          noDataMessage={"No documents found"}
          title={"Documents"}
          columns={columns}
          filters={filters}
          showSearch={false}
          dateRange={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => this._renderToolBar()}
        />
        <Document
          show={showDocumentModal}
          document={selectedDocument}
          onHide={() => this.setState({showDocumentModal: false, selectedDocument: null})}
        />
        <UploadPurchasesModal
          show={showPurchasesModal}
          onHide={() => {
            this.table.current.refresh()
            this.setState({showPurchasesModal: false})
          }}
        />
      </>
    )

  }

}

export default withRouter(Documents);
