import AuthManager from './AuthManager'

export default class Phone {
  static getDefaultCountryCode(){
    let currencyCode = Phone._getDefaultCurrencyCode()
    switch(currencyCode){
      case "EUR": return "+353";
      case "USD": return "+1";
      case "CAD": return "+1";
      case "GBP": return "+44";
      case "AUD": return "+61"
      default:    return "+353";
    }
  }
  static getDefaultCountryIsoCode(){
    let currencyCode = Phone._getDefaultCurrencyCode()
    switch(currencyCode){
      case "EUR": return "ie";
      case "USD": return "us";
      case "CAD": return "ca";
      case "GBP": return "gb";
      case "AUD": return "au"
      default:    return "ie";
    }
  }

  static getCountryIsoCodePriority(){
    let currencyCode = Phone._getDefaultCurrencyCode()
    switch(currencyCode){
      case "CAD": return {ca: 0, us: 1};
      default:    return {us: 0, ca: 1};
    }
  }

  static _getDefaultCurrencyCode(){
    return "EUR"
  }
}
