export default class Currency {

  static format(symbol, amount){
    if(!amount) return `${symbol ? symbol : ''}0.00`
    amount = Number.parseFloat(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    return `${symbol ? symbol : ''}${amount ? amount : ''}`
  }

  static display(symbol, amount){
    return `${symbol}${amount}`
  }
}
