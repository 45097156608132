import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
import LineItems from "./LineItems"
import General from "../../../utils/General";
import Currency from "../../../utils/Currency";

const STATUS_FILTER = {
  name: {
    api: 'status',
    display: 'Status',
  },
  endpoint: {
    url:`${window.Api.Statuses}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const SOURCE_FILTER = {
  name: {
    api: 'source',
    display: 'Source',
  },
  endpoint: {
    url:`${window.Api.Sources}`,
    filters: '',
    orderBy: 'name',
    accessor: ''
  }
}

const FILTERS = [STATUS_FILTER, SOURCE_FILTER]

class Logs extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      filing: props.filing
    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Date',
        id: 'created_at',
        type: "date",
        format: "%d %b %Y",
        Cell: rowInfo => {
          let record = rowInfo.original
          return (
            <div class="d-flex align-items-center">
							<span class="text-dark text-hover-primary fs-7">
                {moment(record.created_at).format('DD MMM YYYY')}
              </span>
						</div>
          )
        }
      },
      {
        Header: 'Status',
        id: 'status',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let record = rowInfo.original
          return (
            <span class="badge badge-success badge-sm fs-base m-lg-auto">
							ALLOCATED
						</span>
          )
        }
      },
      {
        Header: 'Amount',
        id: 'total',
        Cell: rowInfo => {
          let record = rowInfo.original
          let symbol = record.currency.symbol
          return (
            <div class="d-flex align-items-center">
							<a class="text-dark fw-bolder text-hover-primary fs-6">{Currency.format(symbol, record.total)}</a>
						</div>
          )
        }
      },
      {
        Header: 'Tax',
        id: 'tax',
        width: 80,
        Cell: rowInfo => {
          let record = rowInfo.original
          let symbol = record.currency.symbol
          return (
            <div class="d-flex align-items-center">
							<a class="text-dark fw-bolder text-hover-primary fs-6">{Currency.format(symbol, record.tax)}</a>
						</div>
          )
        }
      },
      {
        Header: 'Issued At',
        id: 'issued_at',
        accessor: record => moment(record.issued_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Customer/Supplier',
        id: 'name',
        width: 200,
        accessor: 'name',
        Cell: rowInfo => {
          let record = rowInfo.original
          let entity = record.entity
          let title = entity?.first_name ? entity.first_name : "-"
          let subtitle = entity?.email || ""
          if(entity?.last_name){
            title += " " + entity.last_name
          }
          else if(entity.type = "pos_terminal"){
            title = "POS Terminal"
          }
          return (
            <div class="d-flex align-items-center">
              <div class="d-flex justify-content-start flex-column">
                <a class="text-dark fw-bolder text-hover-primary fs-6">{title}</a>
                <span class="text-muted fw-bold text-muted d-block fs-7">{subtitle}</span>
              </div>
            </div>
          )
        }
      },
      {
        Header: 'Type',
        Cell: rowInfo => {
          let record = rowInfo.original
          let type = record.type.toUpperCase()
          let className = record.type = "sale" ? "success" : "warning"
          return (
            <span class={`badge badge-light-${className} badge-sm fs-base m-lg-auto`}>
							{ type }
						</span>
          )
        }
      },
      {
        Header: 'Source',
        id: 'source',
        accessor: () => "Shopify",
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let record = rowInfo.original
          return (
            <div className={"text-end my-auto"}>
              {/*<View*/}
              {/*  onViewClicked={() => {*/}
              {/*    window.open(`${window.General.Site}/${record.slug}`, '_blank')*/}
              {/*  }}*/}
              {/*/>*/}
              {/*<Edit*/}
              {/*  onEditClicked={() => {*/}
              {/*    window.location.href = `/companies/${record.slug}`*/}
              {/*  }}*/}
              {/*/>*/}
            </div>
          )
        }
      }
    ]

    return columns
  }

  _renderDetail(record){
    return (
      <LineItems record={record}/>
    )
  }

  render() {
    let {
      filing
    } = this.state

    const columns = this._getColumns()

    let endpoint = window.Api.Logs

    if(filing){
      endpoint = `${endpoint}?filing_id=${filing.id}`
    }

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${endpoint}`}
          noDataMessage={"No logs found"}
          title={this.props.title}
          columns={columns}
          //filters={FILTERS}
          showSearch={false}
          dateRange={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          // SubComponent={(row) => {
          //   return this._renderDetail(row.original)
          // }}
        />
      </>
    )

  }

}

Logs.defaultProps = {
  title: "Events"
}

export default withRouter(Logs);
