import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Notify from "../../../utils/Notify"
import FileUploader from "../common/FileUploader";
import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";
import Dropzone from "../common/Dropzone";

export default class UploadPurchasesModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      loading: false,
      error: null,
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      title: props.title,
      show: props.show,
      files: []
    }
  }

  _uploadFiles(){
    let {
      files
    } = this.state

    if(!files){
      Notify.error("Please select the files you wish to upload.")
    }

    let data = {
      files
    }

    this.setState({loading: true})

    Backend.uploadDocuments(data).then(() => {
      this.setState({loading: false})
      Notify.success("Files Uploaded Successfully")
      this.props.onHide()
    }).catch(e => {
      this.setState({loading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      show,
      title,
      body,
      files,
      loading
    } = this.state


    return (
      <Modal
        show={show}
        onHide={() => this.props.onHide()}
        dialogClassName={'modal-dialog-centered modal-md'}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Documents</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">

            <p className="text-muted">
              An Agent will review and process your receipts. This process usually
              takes 2 - 3 working days and we may reach out to you for more information
              if needed
            </p>

            <Dropzone
              accept="image/jpg,image/jpeg,image/png,application/pdf"
              endpoint={window.Api.Files}
              multiple={true}
              maxFiles={100}
              type={"document"}
              onUploaded={file => {
                files.push(file.id)
                this.setState({ files })
              }}
              onUploadStarted={() => {
                this.setState({ loading: true })
              }}
              onUploadFinished={() => {
                this.setState({ loading: false })
              }}
              onRemoved={file => {
                const index = files.indexOf(file.id)
                if(index > -1) {
                  files.splice(index, 1)
                  this.setState({files})
                }
              }}
            />

          </div>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className={`btn btn-light-${this.props.theme} font-weight-bold`}
            onClick={() => this.props.onHide()}
          >
            {this.props.dismissTitleText}
          </button>

          <button
            type="button"
            className={`btn btn-${this.props.theme} font-weight-bold`}
            disabled={loading}
            onClick={() => this._uploadFiles()}
          >
            {this.props.confirmTitleText}
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

UploadPurchasesModal.defaultProps = {
  confirmTitleText: "Upload",
  theme: "primary",
  dismissTitleText: "Cancel"
}
