import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import BaseTable from "./BaseTable"
import General from "../../../utils/General";
import View from "./cells/ViewCell";
import UserModal from "../modals/User";
import ConfirmationModal from "../modals/Confirmation";
import Permissions from "../../../utils/Permissions";
import GenerateReport from "../modals/GenerateReport";

const STATUS_FILTER = {
  name: {
    api: 'status',
    label: 'Status',
  },
  values: [
    {
      label: "Pending",
      value: "pending"
    },
    {
      label: "Processing",
      value: "processing"
    },
    {
      label: "Complete",
      value: "complete"
    },
    {
      label: "Failed",
      value: "failed"
    },
  ]
}

const TYPE_FILTER = {
  name: {
    api: 'type',
    label: 'Type',
  },
  values: [
    {
      label: "Local",
      value: "local"
    },
    {
      label: "OSS",
      value: "oss"
    },
    {
      label: "IOSS",
      value: "ioss"
    },
    {
      label: "EC Sales",
      value: "ec_sales"
    },
    {
      label: "Listings",
      value: "listings"
    }
  ]
}

const FILTERS = [STATUS_FILTER, TYPE_FILTER]

class Reports extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      filing: props.filing,
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps, () => this.table.current.refresh())
  }

  _getColumns(){
    let columns = [
      {
        Header: 'Created At',
        id: 'created_at',
        accessor: report => moment(report.created_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Filing',
        id: 'filing',
        Cell: rowInfo => {
          let report = rowInfo.original
          if(!report.filing){
            return '-'
          }
          let className = 'badge-light-dark'
          let label = `${report.filing.tax_number.country_code} ${report.filing.type.toUpperCase()}`
          if(report.filing.type === "oss"){
            className = 'badge-light-info'
          }else if(report.filing.type === "ioss"){
            className = 'badge-light-success'
          }
          if(report.filing.type === "oss" || report.filing.type === "ioss"){
            label = `${report.filing.type.toUpperCase()}`
          }
          return (
            <span class={`badge ${className} badge-sm fs-base m-lg-auto`}>
							{label}
						</span>
          )
        }
      },
      {
        Header: 'Type',
        id: 'type',
        accessor: report => {
          let type = report.type.toUpperCase()
          if(report.type === "ec_sales"){
            type = "EC Sales"
          }else if(report.type !== "ioss" && report.type !== "oss"){
            type = General.snakeCaseToTitleCase(report.type)
          }
          return type
        },
      },
      {
        Header: 'Status',
        id: 'status',
        Cell: rowInfo => {
          let report = rowInfo.original
          let className = 'badge-success'
          if(report.status === "failed"){
            className = 'badge-danger'
          }else if(report.status === "processing"){
            className = 'badge-light-success'
          }else if(report.status === "pending"){
            className = 'badge-light-warning'
          }
          return (
            <span class={`badge ${className} badge-sm fs-base m-lg-auto`}>
							{General.snakeCaseToTitleCase(report.status)}
						</span>
          )
        }
      },
      {
        Header: 'Date From',
        id: 'date_from',
        accessor: report => report.date_from ? moment(report.date_from).format('DD MMM YYYY') : '',
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: 'Date To',
        id: 'date_to',
        accessor: report => report.date_to ? moment(report.date_to).format('DD MMM YYYY') : '',
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let report = rowInfo.original
          return (
            <div className={"my-auto float-end"}>
              {
                report.file &&
                <View
                  onViewClicked={() => {
                    window.open(`${report.file.url}`, '_blank')
                  }}
                />
              }
            </div>
          )
        }
      }
    ]

    return columns
  }

  _renderToolBar() {
    return (
      <>
        {
          Permissions.hasCreatePermission(Permissions.REPORTS) &&
          <a
            className="btn btn-primary ms-5 font-weight-bolder"
            onClick={() => {
              this.setState({
                showGenerateReportModal: true,
              })
            }}
            style={{ height: "45px" }}
          >
          <span className="svg-icon svg-icon-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect
                  fill="#000000"
                  x="4"
                  y="11"
                  width="16"
                  height="2"
                  rx="1"
                ></rect>
                <rect
                  fill="#000000"
                  opacity="0.3"
                  transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                  x="4"
                  y="11"
                  width="16"
                  height="2"
                  rx="1"
                ></rect>
              </g>
            </svg>
          </span>
            Generate Report
          </a>
        }
      </>
    )
  }

  _renderGenerateReportModal(){
    let { filing, showGenerateReportModal } = this.state

    return (
      <GenerateReport
        filing={filing}
        show={showGenerateReportModal}
        onHide={() => this.setState({ showGenerateReportModal: false, report: null })}
        onGenerating={(member) => {
          this.setState({ showGenerateReportModal: false, showConfirmationModal: true, report: null })
        }}
      />
    )
  }

  _renderConfirmationModal(){
    let { showConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Request Submitted"}
        body={"Your report is being generated and you will receive an email once it has been completed"}
        loading={this.state.loading}
        onConfirm={() => {
          this.table.current.refresh()
          this.setState({showConfirmationModal: false, report: null})
        }}
        onHide={() => {
          this.table.current.refresh()
          this.setState({showConfirmationModal: false, report: null})
        }}
      />
    )
  }

  render() {
    let {
      filing
    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Reports}?filing_id=${filing?.id || ''}`}
          noDataMessage={"No reports found"}
          title={"Reports"}
          columns={columns}
          filters={FILTERS}
          showSearch={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => this._renderToolBar()}
        />
        {this._renderGenerateReportModal()}
        {this._renderConfirmationModal()}
      </>
    )

  }

}

export default withRouter(Reports);
