import React from "react"

import Card from "./components/structure/Card";
import General from "./components/settings/General"

import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";


export default class Settings extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  componentDidMount() {

  }

  render() {
    let {
      partner,
      currentUser,
      partnerInfo,
      partners,
      subscription
    } = this.state

    let name = `${currentUser.user.first_name} ${currentUser.user.last_name}`

    return (
      <>
        <Card
          className="card mt-5 mb-5 mb-xl-10"
          showHeader={false}
          bodyClassName={""}
        >
          <div className="tab-content" id="analytics">
            <div className="tab-pane fade show active" id="profile" role="tabpanel">
              <General/>
            </div>
          </div>
        </Card>
      </>
    )
  }

}
