import React from "react"
import {Link} from "react-router-dom";
// import PasswordStrengthBar from 'react-password-strength-bar';
// import ReactPasswordStrength from 'react-password-strength';

import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";

export default class EORIDetails extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      hasEORI: true,
      isLoading: false,
      ...this._getState(props)
    }
  }

  _getState(props){
    return {
      data: {company: props.companyMember?.company.id}
    }
  }

  _saveEORIDetails(){
    let {
      data
    } = this.state

    if(!data.eori_no){
      Notify.error("Please provide your EORI number")
      return
    }

    this.props.onIsLoading(true)

    Backend.updateCompany(data.company, data).then(response => {
      Notify.success("EORI Details Saved")
      this.props.onEORIDetailsSaved()
      this.props.onIsLoading(false)
    }).catch(e => {
      this.props.onIsLoading(false)
      Notify.error(e.message)
    })
  }

  render() {
    let {
      data,
      hasEORI,
      isLoading
    } = this.state

    return (
      <>
        <div className="current" data-kt-stepper-element="content">
          <div className="w-100">
            <div className="pb-10 pb-lg-12">
              <h2 className="fw-bolder text-dark">Tax Info</h2>
              <div className="text-muted fw-bold fs-6">If you need more info, please check out
                <a href="https://www.taxmatic.com/contact/" target="_blank" className="link-primary fw-bolder"> Help Page</a>.
              </div>
            </div>

            <div className="tab-content">

              <div className="tab-pane fade yes-vat active show" role="tabpanel">

                <label className="d-flex align-items-center form-label mb-3">
                  Do You Have An EORI Number?
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-original-title="EORI stands for “Economic Operators Registration and Identification number”. Businesses and people wishing to trade must use the EORI number as an identification number in all customs procedures when exchanging information with Customs administrations."
                    aria-label="EORI stands for “Economic Operators Registration and Identification number”. Businesses and people wishing to trade must use the EORI number as an identification number in all customs procedures when exchanging information with Customs administrations."
                  />
                </label>

                <div className="row mb-2" data-kt-buttons="true">
                  <div className="col">
                    <label
                      className={`btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 ${hasEORI ? '' : 'active'}`}
                      id="btn-no-eori"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="account_team_size"
                        checked={!hasEORI}
                        onChange={e => {
                          this.setState({hasEORI: !hasEORI})
                        }}
                      />
                        <a className="fw-bolder fs-3">No</a>
                    </label>
                  </div>
                  <div className="col">
                    <label
                      className={`btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4 ${hasEORI ? 'active' : ''}`}
                      id="btn-yes-eori"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="account_team_size"
                        checked={hasEORI}
                        onChange={e => {
                          this.setState({hasEORI: !hasEORI})
                        }}
                      />
                      <a className="fw-bolder fs-3">Yes</a>
                    </label>
                  </div>
                </div>

                <div className="tab-content">

                  <div className={`tab-pane fade no-eori ${hasEORI ? '' : 'active'} show`} role="tabpanel">

                    <label className="my-7">
                      Don't have an EORI number? Watch this quick tutorial to get one.
                    </label>
                    <br/>

                    <iframe
                      id="eoriVid"
                      width="400"
                      height="225"
                      src="https://www.youtube.com/embed/HuChbV7mu3c"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen=""
                    />

                  </div>

                  <div className={`tab-pane fade yes-eori ${hasEORI ? 'active' : ''} show`} role="tabpanel">

                    <div className="fv-row mt-7 mb-7">
                      <label className="form-label fw-bolder required text-dark fs-6">
                        EORI Number
                      </label>
                      <input
                        className="form-control form-control-lg form-control-solid"
                        type="text"
                        placeholder=""
                        name="text"
                        autoComplete="off"
                        value={data.eori_no}
                        onChange={e => {
                          data.eori_no = e.target.value
                          this.setState({data})
                        }}
                      />
                    </div>

                  </div>

                </div>

              </div>

            </div>
          </div>
        </div>
        <div className="d-flex flex-stack pt-15">
          <div className="mr-2"/>
          <div>
            <button
              type="button"
              className="btn btn-lg btn-primary"
              data-kt-stepper-action="next"
              onClick={e => this._saveEORIDetails()}
            >
              Continue
              <span className="svg-icon svg-icon-4 ms-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                        fill="black"></rect>
                  <path
                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                    fill="black"></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </>
    )
  }

}
