import React from "react"

import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Event from "../utils/Event";
import {Link} from "react-router-dom";
import BankAccountSetUp from "./components/modals/BankAccountSetUp";
import PaymentsTable from "./components/tables/Payments";
import AsyncStorage from "../utils/AsyncStorage";
import General from "../utils/General";
import Permissions from "../utils/Permissions";

export default class Payments extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  componentDidMount() {
    let {
      currentUser
    } = this.state

    let status = General.getUrlParameter("status")

    if(status === "success"){
      this.setState({isLoading: true})
      AsyncStorage.getItem('gocardless_data').then(goCardlessData => {
        let data = {
          type: 'gocardless',
          state: goCardlessData
        }

        Backend.addIntegration(data).then(response => {
          Notify.success("Go Cardless Connected")
          AsyncStorage.removeItem('gocardless_data')
          currentUser.company.bank_account_connected = true
          this.setState({currentUser, isLoading: false})
        })
      })
    }else if(status === "failure"){
      Notify.error("Failed to connect GoCardless, please try again.")
    }

    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search)

    params.delete("status")
    let pathName = window.location.pathname
    if(params.toString()){
      pathName += "?" + params.toString()
    }
    window.history.replaceState(null, window.document.title, pathName)
  }

  _connectGoCardless(){
    let data = {
      company: AuthManager.currentUser.company.id,
      redirect_url: window.location.href,
      type: "gocardless"
    }

    this.setState({isLoading: true})

    Backend.addIntegration(data).then(response => {
      AsyncStorage.setItem('gocardless_data', response.state)
      window.location = response.redirect_url
    }).catch(e => {
      this.setState({isLoading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      isLoading,
      currentUser,
      showBankAccountForm
    } = this.state

    let bankAccountConnected = currentUser.company.bank_account_connected
    let title = `Bank Account \n Not Connected`
    if(bankAccountConnected){
      title = `Bank Account \n Connected`
    }

    return (
      <div className="overlay">
        {
          (!bankAccountConnected && Permissions.hasCreatePermission(Permissions.PAYMENTS)) &&
          <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-12 p-6">
            <i
              className="ki-duotone ki-information fs-2tx text-warning me-4"
            >
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </i>
            <div className="d-flex flex-stack flex-grow-1 ">
              <div className=" fw-semibold">
                <h4 className="text-gray-900 fw-bold">We need your attention!</h4>
                <div className="fs-6 text-gray-700 ">Your bank account has not been connected. To enable filings, please
                  <a
                    href="#"
                    className="fw-bold"
                    onClick={() => this._connectGoCardless()}
                  > Connect Your Bank Account
                  </a>.
                </div>
              </div>
            </div>
          </div>
        }

        <div
          className={`card bgi-position-y-bottom bank-account ${bankAccountConnected ? '' : 'bank-account-not-connected'} bgi-position-x-end bgi-no-repeat bgi-size-cover min-h-250px bg-primary mb-5 mb-xl-8`}
          style={{
            backgroundPosition: '95% 20px',
            backgroundSize: '200px auto',
            backgroundImage:`url(${require('../assets/media/logos/bank.png')})`
          }}
        >
          <div className="card-body d-flex flex-column justify-content-center">
            <h3 className="text-white fs-2x fw-bolder line-height-lg mb-5">
              {title}
            </h3>
            {
              bankAccountConnected &&
              <>
                <p className="text-white mt-5 w-400px">
                  Payments &amp; Refunds will be automatically debited/credited.
                </p>
              </>
            }
          </div>
        </div>

        {
          bankAccountConnected &&
          <div className="row">
            <div className="col-sm-12 col-xl-12">
              <PaymentsTable/>
            </div>
          </div>
        }

        {
          isLoading &&
          <div className="overlay-layer bg-dark-o-10">
            <div className="spinner spinner-primary"></div>
          </div>
        }

      </div>
    )
  }

}
