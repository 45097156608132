import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import Permissions from "../../../utils/Permissions";
import General from "../../../utils/General";
import AsyncSelect from "../common/AsyncSelect";
import Email from "../../../utils/Email";

export default class User extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props) {
    return {
      ...props,
      teamMember: props.teamMember || General.clone({user:{}}),
    }
  }

  _isFormValid() {
    let { id, first_name, last_name, email, password } = this.state.teamMember.user

    let error = null

    if (first_name == null || first_name.length === 0) {
      error = 'Please enter a first name'
    } else if (last_name == null || last_name.length === 0) {
      error = 'Please enter a last name'
    }  else if (!Email.isValid(email)) {
      error = 'Please enter a valid email'
    } else if(!password || !password.match(/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/)){
      error = "Please ensure your password contains letters, numbers and is at least 8 characters long"
    } else if (!this.state.teamMember.role) {
      error = 'Please select a role'
    }

    if (error) {
      Notify.error(error)
      return false
    }
    return true
  }

  _handleAddMember(){
    if (!this._isFormValid()) {
      return
    }
    if (this.state.teamMember.user.id) {
      this._updateTeamMember()
    } else {
      this._addTeamMember()
    }
  }

  _updateTeamMember(){
    let { teamMember } = this.state

    this.setState({ loading: true })
    let data = General.clone(teamMember)
    data.role = data.role.id
    Backend.updateTeamMember(data)
      .then((member) => {
        Notify.success('Member Updated Successfully')
        this.setState({ loading: false })
        this.props.onAdded(member)
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _addTeamMember(){
    let { teamMember } = this.state

    this.setState({ loading: true })
    let data = General.clone(teamMember)
    if(data.role){
      data.role = data.role.id
    }
    Backend.addTeamMember(data)
      .then((member) => {
        Notify.success('Member Added Successfully')
        this.setState({ loading: false })
        this.props.onAdded(member)
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  render() {
    let {
      show,
      teamMember,
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
          dialogClassName={"modal-dialog modal-dialog-centered mw-650px"}
        >
          <Modal.Header>
            <h2 className="fw-bold">Add User</h2>
          </Modal.Header>

          <Modal.Body>

            <div className="form fv-plugins-bootstrap5 fv-plugins-framework">

              <div className="d-flex flex-column me-n7 pe-7" id="kt_modal_add_user_scroll">

                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">First Name</label>
                  <input
                    type="text"
                    name="user_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="First Name"
                    value={teamMember.user.first_name}
                    onChange={(e) => {
                      teamMember.user.first_name = e.target.value
                      this.setState({ teamMember, error: null })
                    }}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>

                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Last Name</label>
                  <input
                    type="text"
                    name="user_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Last Name"
                    value={teamMember.user.last_name}
                    onChange={(e) => {
                      teamMember.user.last_name = e.target.value
                      this.setState({ teamMember, error: null })
                    }}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>

                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Email</label>
                  <input
                    type="email"
                    name="user_email"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Email"
                    value={teamMember.user.email}
                    disabled={!!teamMember.user.id}
                    onChange={(e) => {
                      teamMember.user.email = e.target.value
                      this.setState({ teamMember, error: null })
                    }}
                  />
                  <div className="fv-plugins-message-container invalid-feedback" bis_skin_checked="1"></div>
                </div>

                {
                  !teamMember.user.id &&
                  <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                    <label className="required fw-semibold fs-6 mb-2">Password</label>
                    <input
                      type="password"
                      name="user_password"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      placeholder="Password"
                      value={teamMember.user.password}
                      onChange={(e) => {
                        teamMember.user.password = e.target.value
                        this.setState({ teamMember, error: null })
                      }}
                    />
                  </div>
                }

                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="required fw-semibold fs-6 mb-2">Role</label>
                  <div className="select-wrapper">
                    <AsyncSelect
                      endpoint={window.Api.Roles}
                      filter={`company_id=${AuthManager.currentUser.company?.id}`}
                      className="form-control h-auto border-0 form-control-solid c-selectbox"
                      placeholder={'Select A Role'}
                      value={General.getSingleAsyncOption(teamMember.role)}
                      onSelected={(value) => {
                        teamMember.role = value
                        this.setState({ teamMember, error: null })
                      }}
                      getOptions={(roles) => {
                        return roles.map((role) => ({
                          value: role.id,
                          label: role.name,
                          data: role,
                        }))
                      }}
                    />
                  </div>
                </div>

                <div className="fv-row">



                </div>
              </div>


              <div className="text-end pt-15" bis_skin_checked="1">
                <button
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                  onClick={e => {
                    e.preventDefault()
                    this.props.onHide()
                  }}
                >
                  Discard
                </button>

                <button
                  className="btn btn-primary"
                  disabled={this.state.loading}
                  onClick={e => {
                    e.preventDefault()
                    this._handleAddMember()
                  }}
                >
                  <span className="indicator-label">Submit</span>
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>

              <div bis_skin_checked="1"></div>
            </div>

          </Modal.Body>

        </Modal>
      </>
    )
  }

}
