import React from "react"

import AuthManager from "../utils/AuthManager";

import TeamMembers from "./components/tables/TeamMembers";

export default class Users extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  render() {
    let {
      stats
    } = this.state

    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <TeamMembers/>
          </div>
        </div>
      </>
    )
  }

}