import React from "react";
import Modal from 'react-bootstrap/Modal'
import StickyBox from "react-sticky-box";
import AsyncSelect from "../common/AsyncSelect";
import Select from "../common/Select";
import moment from "moment/moment";
import Flatpickr from "react-flatpickr";
import SmartList from "../common/SmartList";
import Calculations from "../../../utils/Calculations";
import LineItem from "../digitising/LineItem";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";
import Currency from "../../../utils/Currency";

export default class Digitise extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      record: props.record ? props.record : {document: props.document.id, subtotal: 0, total: 0, line_items: []},
      document: props.document,
      show: props.show,
    }
  }

  _removeLineItem(lineItem){
    let { record } = this.state

    let index = record.line_items.indexOf(lineItem)
    record.line_items.splice(index, 1)
    this.setState({record}, () => this._updateTotals())
  }

  _updateLineItem(lineItem, index){
    let { record } = this.state

    record.line_items[index] = lineItem

    this.setState({
      record,
    }, () => this._updateTotals())
  }

  _updateTotals(){
    let { record } = this.state

    record.tax = Calculations.itemsVatTotal(record.line_items)
    record.subtotal = Calculations.itemsSubTotal(record.line_items)
    record.total = Calculations.itemsTotal(record.line_items, record.tax)

    let tax = Calculations.itemsVatTotal(record.line_items)
    let total = Calculations.itemsTotal(record.line_items, tax)
    let subtotal = Calculations.itemsSubTotal(record.line_items)

    this.setState({
      record,
      tax,
      total,
      subtotal
    }, () => this._saveProgress())
  }

  _saveProgress =  General.debounce(() => {
    let {
      record,
      document
    } = this.state

    let data = {
      data: record
    }

    Backend.saveRecordProgress(data, document).then(response => {

    }).catch(e => Notify.error(e.message))
  }, 500)

  _createTransaction(){
    let {
      record,
      document
    } = this.state

    this.setState({isLoading: true})

    record.company = document.company.id

    Backend.addTransaction(record).then(response => {
      Notify.success("Transaction Created")
      this.setState({isLoading: false})
    }).catch(e => {
      this.setState({isLoading: false})
      Notify.error(e.message)
    })
  }

  _renderFileWindow(file){
    let re = /(?:\.([^.]+))?$/;
    let fileType = re.exec(file.url)[1];

    if(fileType === "pdf"){
      return <iframe src={file.url} width="100%" height="100%"/>
    }
    return (
      <img
        key={file.url}
        src={file.url}
        width="100%" height="100%"
        style={{ objectFit: "contain" }}
      />
    )
  }

  render() {
    let {
      show,
      record,
      document,
      isLoading,
      showEntityModal
    } = this.state

    if(!document) return null

    let currency = record.currency ? {value: record.currency.id, label: record.currency.name, data: record.currency} : null
    let entity = record.entity ? {value: record.entity.id, label: `${record.entity.first_name} ${record.entity.last_name}`, data: record.entity} : null
    let entityFilter = `company_id=${document.company?.id}&supplier=${record.type === "purchase"}&customer=${record.type === "sale"}`
    let entityTitle = 'Supplier/Customer'
    if(record.type === "purchase"){
      entityTitle = "Supplier"
    }else if(record.type === "sale"){
      entityTitle = "Customer"
    }

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          dialogClassName={"w-100 modal-90vw"}
          className={'modal-digitise'}
        >
          <div className="row">
            <div key={document.id} className="col-lg-6 files-preview" style={{ overflowY: 'hidden', minHeight: '100vh' }}>
              <div className="set-viewport-height">

                <div className="file">
                  {this._renderFileWindow(document.file)}
                </div>

              </div>
            </div>
            <div className="col-lg-6">
              <StickyBox offsetTop={20} style={{width: '100%', background: 'white'}}>
              <Modal.Header closeButton>
                <h2>{document.company.name} | {General.toTitleCase(document.file.name)}</h2>
              </Modal.Header>
              <div className="card">
                <div className="card-body">
                  <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                    <label className="fs-6 mb-2">Reference</label>
                    <input
                      type="text"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      value={record.reference}
                      disabled={!!record.id}
                      onChange={e => {
                        record.reference = e.target.value || null
                        this.setState({record, error: null}, () => this._saveProgress())
                      }}
                    />
                  </div>

                  <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                    <label className="required fs-6 mb-2">Type</label>
                    <Select
                      value={record.type}
                      disabled={!!record.id}
                      options={[
                        {
                          label: "Sale",
                          value: "sale"
                        },
                        {
                          label: "Purchase",
                          value: "purchase"
                        }
                      ]}
                      placeholder={"Type"}
                      getOptionLabel={type => type.label}
                      getOptionValue={type => type.value}
                      className="form-control form-control-solid h-auto c-selectbox"
                      classNamePrefix="filter-select"
                      onSelected={type => {
                        record.type = type.value
                        this.setState({record}, () => this._saveProgress())
                      }}
                    />
                  </div>

                  <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                    <label className="required fs-6 mb-2">Sub Type</label>
                    <Select
                      value={record.subtype}
                      disabled={!!record.id}
                      options={[
                        {
                          label: "Invoice",
                          value: "invoice"
                        },
                        {
                          label: "Receipt",
                          value: "receipt"
                        },
                        {
                          label: "Credit Note",
                          value: "credit_note"
                        }
                      ]}
                      placeholder={"Sub Type"}
                      getOptionLabel={type => type.label}
                      getOptionValue={type => type.value}
                      className="form-control form-control-solid h-auto c-selectbox"
                      classNamePrefix="filter-select"
                      onSelected={type => {
                        record.subtype = type.value
                        this.setState({record}, () => this._saveProgress())
                      }}
                    />
                  </div>

                  <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                    <label className="required fw-semibold fs-6 mb-2">Currency</label>
                    <AsyncSelect
                      endpoint={window.Api.Currencies}
                      orderBy={""}
                      disabled={!!record.id}
                      className="form-control h-auto border-0 form-control-solid c-selectbox"
                      placeholder={'Select Currency'}
                      value={currency}
                      onSelected={(currency) => {
                        record.currency = currency
                        this.setState({ record, error: null }, () => this._saveProgress())
                      }}
                      getOptions={(currencies) => {
                        return currencies.map((currency) => ({
                          value: currency.id,
                          label: currency.name,
                          data: currency,
                        }))
                      }}
                    />
                  </div>

                  <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                    <label className="fw-semibold fs-6 mb-2">Issued At</label>
                    <div className="input-group date">
                      <Flatpickr
                        value={record.issued_at ? record.issued_at : null}
                        className="form-control form-control-solid"
                        placeholder="Issued At"
                        disabled={!!record.id}
                        options={{
                          static: true,
                          enableTime: false,
                          noCalendar: false,
                          dateFormat: "Y-m-d",
                          maxDate: moment().format("YYYY-MM-DD"),
                        }}
                        onChange={(dates) => {
                          record.issued_at = moment(dates[0]).format("YYYY-MM-DD")
                          this.setState({record}, () => this._saveProgress())
                        }}
                      />
                    </div>
                  </div>

                  <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                    <label className="required fs-6 mb-2">Description</label>
                    <input
                      type="text"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      value={record.description}
                      disabled={!!record.id}
                      onChange={e => {
                        record.description = e.target.value
                        this.setState({record, error: null}, () => this._saveProgress())
                      }}
                    />
                  </div>
                  <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                    <label className="required fw-semibold fs-6 mb-2">{entityTitle}</label>
                    <AsyncSelect
                      endpoint={window.Api.Entities}
                      creatable={ true }
                      filter={entityFilter}
                      orderBy={""}
                      disabled={!!record.id}
                      className="form-control h-auto border-0 form-control-solid c-selectbox"
                      placeholder={'Select A Supplier/Customer'}
                      value={entity}
                      onSelected={(entity) => {
                        record.entity = entity
                        this.setState({ record, error: null }, () => this._saveProgress())
                      }}
                      onCreated={entity => {
                        record.entity = {company: document.company.id, first_name: entity.label, customer: record.type === "sale", supplier: record.type === "purchase"}
                        this.setState({record, showEntityModal: true})
                      }}
                      getOptions={(entities) => {
                        return entities.map((entity) => ({
                          value: entity.id,
                          label: `${entity.first_name} ${entity.last_name}`,
                          data: entity,
                        }))
                      }}
                    />
                  </div>

                  <h3>Lines</h3>

                  <div className="position-relative">
                    <div className="table-responsive">
                      <table className="table table-scroll" style={{overflowX: 'scroll', whiteSpace: 'nowrap'}}>
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th>Subtotal</th>
                            <th>TAX %</th>
                            <th>TAX</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody style={{width: '100%'}}>
                        {record.line_items.map((lineItem, index) => {
                          return (
                            <LineItem
                              editable={!!record.id}
                              lineItem={lineItem}
                              onUpdated={line_item => this._updateLineItem(line_item, index)}
                              onDelete={line_item => this._removeLineItem(line_item)}
                            />
                          )
                        })}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-lg-7"/>
                    <div className="col-lg-5 justify-content-end">
                      <div className="calculations">
                        <div className="row">
                          <div className="col-lg-7 text-start">
                            <h4 className="text-dark">Subtotal:</h4>
                          </div>
                          <div className="col-lg-5 text-end">
                            <h4 className="text-dark">{Currency.format(record.currency?.symbol, record.subtotal)}</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-7 text-start">
                            <h4 className="text-dark">Tax:</h4>
                          </div>
                          <div className="col-lg-5 text-end">
                            <h4 className="text-dark">{Currency.format(record.currency?.symbol, record.tax)}</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-7 text-start">
                            <h4 className="text-dark">Total:</h4>
                          </div>
                          <div className="col-lg-5 text-end">
                            <h4 className="text-dark">{Currency.format(record.currency?.symbol, record.total)}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => this.props.onHide()}
                  >
                    Cancel
                  </button>
                  {
                    !record.id &&
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this._createTransaction()}
                      disabled={isLoading}
                    >
                      Save
                    </button>
                  }
                </div>
              </div>
              </StickyBox>
            </div>
            </div>
        </Modal>
      </>
    )
  }

}
