import React from 'react'

import BarChart from '../chart/BarChart'

import Backend from '../../../utils/Backend'
import Currency from '../../../utils/Currency'
import FetchHelper from '../../../utils/FetchHelper'
import General from '../../../utils/General'

export default class Chart extends React.Component  {
  constructor(props){
    super(props)

    this.state = {
      data: [],
      labels: [],
      ...this._getState(props),
    }
  }

  componentDidMount(){
    this._setFilings()
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.filing?.tax_number.id !== this.state.filing?.tax_number.id){
      this.setState(this._getState(nextProps), () => this._setFilings())
    }
  }

  _getState(props){
    return {
      filing: props.filing,
    }
  }

  _setFilings(){
    let {
      filing
    } = this.state

    this.setState({ data: [], loading: true })
    return Backend.getFilings(filing.tax_number.id, 6)
    .then(response => {
      let filings = response.results
      this.props.updateCount(filings.length)
      let borderWidth = 1
      let data = [
        {
          borderWidth,
          label: 'Sales',
          backgroundColor: "#082f49",
          borderColor: "#082f49",
          data: filings.map(filing => filing.sales_tax)
        },
        {
          borderWidth,
          label: 'Purchases',
          backgroundColor: "#0ea5e9",
          borderColor: "#0ea5e9",
          data: filings.map(filing => filing.purchases_tax)
        },
        {
          borderWidth,
          label: 'Net',
          backgroundColor: "#569cb9",
          borderColor: "#569cb9",
          data: filings.map(filing => filing.net)
        }
      ]
      let labels = filings.map(filing => filing.period)
      window.General.DefaultCurrency = filings[0].currency
      this.setState({
        data,
        labels,
        filings,
      })
    })
  };


  render(){
    let { filings, data, labels } = this.state

    if(!filings){
      return null
    }

    return (
      <>
        <BarChart
          data={data}
          labels={labels}
          color={'#000'}
        />
      </>
    )
  }
}

Chart.defaultProps = {

}
