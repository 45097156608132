import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import LeftMenu from "./components/structure/LeftMenu";
import Header from "./components/structure/Header";
import Footer from "./components/structure/Footer";

import AuthManager from "../utils/AuthManager";
import Permissions from "../utils/Permissions";

import Dashboard from "./Dashboard";
import Filings from "./Filings";
import Filing from "./Filing";
import Transactions from "./Transactions";
import Sales from "./Sales";
import Purchases from "./Purchases";
import Messages from "./Messages";
import Stores from "./Stores";
import Payments from "./Payments";
import Team from "./Team";
import Roles from "./Roles";
import Settings from "./Settings";
import Documents from "./Documents";
import HighRisk from "./HighRisk";
import TaxNumbers from "./TaxNumbers";
import Reports from "./Reports";
import Subscription from "./Subscription";

export default class App extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  componentDidMount() {
    if(document.getElementById("root").className === "bg-body"){
      document.getElementById("root").className = "header-fixed header-tablet-and-mobile-fixed aside-enabled aside-fixed"
    }
  }

  _renderRoutes() {
    let routes = []

    routes.push(<Route exact path="/" component={Dashboard} />)
    routes.push(<Route exact path="/settings" component={Settings} />)

    if(Permissions.hasViewPermission(Permissions.TRANSACTIONS)){
      routes.push(<Route exact path="/transactions" component={Transactions} />)
      routes.push(<Route exact path="/sales" component={Sales} />)
      routes.push(<Route exact path="/purchases" component={Purchases} />)
      routes.push(<Route exact path="/high-risk" component={HighRisk} />)
    }

    if(Permissions.hasViewPermission(Permissions.FILINGS)){
      routes.push(<Route exact path="/filings" component={Filings} />)
      routes.push(<Route exact path="/filing/:filingId" component={Filing} />)
    }

    if(Permissions.hasViewPermission(Permissions.INTEGRATIONS)){
      routes.push(<Route exact path="/stores" component={Stores} />)
    }

    if(Permissions.hasViewPermission(Permissions.TEAM_MEMBERS)){
      routes.push(<Route exact path="/team" component={Team} />)
    }

    if(Permissions.hasViewPermission(Permissions.ROLES)){
      routes.push(<Route exact path="/roles" component={Roles} />)
    }

    if(Permissions.hasViewPermission(Permissions.MESSAGES)){
      routes.push(<Route exact path="/messages" component={Messages} />)
    }

    if(Permissions.hasViewPermission(Permissions.DOCUMENTS)){
      routes.push(<Route exact path="/documents" component={Documents} />)
    }

    if(Permissions.hasViewPermission(Permissions.ROLES)){
      routes.push(<Route exact path="/roles" component={Roles} />)
    }

    if(Permissions.hasViewPermission(Permissions.PAYMENTS)){
      routes.push(<Route exact path="/payments" component={Payments} />)
      routes.push(<Route exact path="/subscription" component={Subscription} />)
    }

    if(Permissions.hasViewPermission(Permissions.TAXES)){
      routes.push(<Route exact path="/tax-numbers" component={TaxNumbers} />)
    }

    if(Permissions.hasViewPermission(Permissions.REPORTS)){
      routes.push(<Route exact path="/reports" component={Reports} />)
    }

    return (
      <Switch>
        {routes.map(route => {
          return route
        })}
        <Redirect to="/" />
      </Switch>
    )
  }

  render() {

    let marginTop = window.location.pathname === '/payments' ? '' : -40
    return (
      <>
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <LeftMenu/>
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
              <Header/>
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
                style={{marginTop}}
              >
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  <div id="kt_content_container" className="container-xxl">
                    {this._renderRoutes()}
                  </div>
                </div>
              </div>
              <Footer/>
            </div>
          </div>
        </div>
      </>
    )
  }

}
