import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import SignatureCanvas from "react-signature-canvas";
import LocationInput from "../common/LocationInput";
import FileUploader from "../common/FileUploader";
import ShopifyHelp from "./ShopifyHelp";
import Select from "../common/Select";
import CountrySelect from "../common/CountrySelect";
import Dropzone from "../common/Dropzone";
import SmartList from "../common/SmartList";

export default class Store extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props)
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      show: props.show,
      placeholder: "",
      integrationData: {
        company: AuthManager.currentUser.company.id,
        redirect_url: `${window.location.origin}${window.location.pathname}`,
        type: "",
        data: {
          return_url: `${window.location.origin}${window.location.pathname}`,
        }
      },
    }
  }

  _connectStore(){
    let {
      files,
      integrationData
    } = this.state

    let title = ""
    if(integrationData.data.ship_from_evaluation_method === "order_status"){
      title = "order status"
    }else if(integrationData.data.ship_from_evaluation_method === "note"){
      title = "note"
    }

    if(!integrationData.data.shop){
      Notify.error("Please enter your shop url")
      return
    }

    if(integrationData.type === "shopify"){
      if(!integrationData.data.shop.includes('.myshopify.com')){
        Notify.error("Please enter a valid Shopify shop url")
        return
      }
    }

    if(!integrationData.data.shop.startsWith('http')){
      integrationData.data.shop = 'https://' + integrationData.data.shop
    }

    if(integrationData.type === "woocommerce"){
      if(!integrationData.data.ship_from_country_code){
        Notify.error("Please select the country where your warehouse is located")
        return
      }

      if(!integrationData.multiple_warhouses){
        Notify.error("Please indicate if you ship from multiple locations")
        return
      }

      if(integrationData.multiple_warhouses === "yes"){
        if(!integrationData.data.ship_from_evaluation_method){
          Notify.error("Please select how you identify warehouse location")
          return
        }

        if(integrationData.data.warehouses.length < 1){
          Notify.error("Please add your warehouse locations")
          return
        }

        let error = null

        integrationData.data.warehouses.map((warehouse, index) => {
          let warehouseNumber = General.toOrdinal(index+1)
          if (!warehouse.comparison_value){
            error = `Please enter the ${title} on the ${warehouseNumber} warehouse`
          }else if(!warehouse.country_code){
            error = `Please select the ${warehouseNumber} warehouse location`
          }
        })

        if(error){
          Notify.error(error)
          return;
        }

      }

    }

    this.setState({isLoading: true})

    Backend.addIntegration(integrationData).then(response => {
      if(integrationData.type !== "amazon_file"){
        window.location = response.redirect_url
      }else{
        Notify.success("Store Connected!")
        this.props.onStoreAdded()
      }
    }).catch(e => {
      this.setState({isLoading: false})
      Notify.error(e.message)
    })
  }

  _renderWarehousePicker(location, index){
    let {
      integrationData
    } = this.state

    let placeholder = ""
    if(integrationData.data.ship_from_evaluation_method === "order_status"){
      placeholder = "Order Status"
    }else if(integrationData.data.ship_from_evaluation_method === "note"){
      placeholder = "Note"
    }

    return (
      <>
        <label className="form-label fw-bolder text-dark fs-6 p-0">{placeholder}</label>
        <input
          className="form-control form-control-lg form-control-solid"
          placeholder={placeholder}
          value={integrationData.data.warehouses[index].comparison_value}
          onChange={e => {
            integrationData.data.warehouses[index].comparison_value = e.target.value
            this.setState({integrationData})
          }}
        />
        <label className="form-label mt-5 fw-bolder text-dark fs-6 p-0">Location</label>
        <CountrySelect
          value={integrationData.data.warehouses[index].country_code}
          onSelected={country => {
            integrationData.data.warehouses[index].country_code = country.value
            if(index === 0){
              integrationData.data.ship_from_country_code = country.value
            }
            this.setState({ integrationData })
          }}
        />
      </>
    )
  }

  render() {
    let {
      show,
      files,
      isLoading,
      placeholder,
      integrationData,
      showShopifyHelp,
    } = this.state

    let className = ""
    if(isLoading){
      className = "overlay overlay-block"
    }

    let title = "Shop"

    if(integrationData.type === "shopify"){
      title = "Shopify"
    }else if(integrationData.type === "woocommerce"){
      title = "WooCommerce"
    }else if(integrationData.type === "amazon_file"){
      title = "Amazon"
    }

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
          dialogClassName={`modal-dialog modal-dialog-centered mw-550px ${className}`}
        >
          <Modal.Header>
            <h2 className="w-80 d-flex">Connect {title} Account</h2>
            { integrationData.type === "shopify" &&
              <div className="text-muted fw-bold fs-6 w-20">
                <a
                  href="#"
                  className="text-primary fw-bolder"
                  onClick={() => {
                    this.setState({showShopifyHelp: true})
                  }}
                >
                  Help Page
                </a>
              </div>
            }
          </Modal.Header>

          <Modal.Body>

            <div className="form fv-plugins-bootstrap5 fv-plugins-framework">
              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="form-label fw-bolder text-dark fs-6 required mb-2">Store</label>
                <Select
                  value={integrationData.type}
                  disabled={!!integrationData.id}
                  options={[
                    {
                      label: "Shopify",
                      value: "shopify"
                    },
                    {
                      label: "WooCommerce",
                      value: "woocommerce"
                    },
                    {
                      label: "Amazon Marketplace",
                      value: "amazon_file"
                    }
                  ]}
                  placeholder={"Store"}
                  getOptionLabel={type => type.label}
                  getOptionValue={type => type.value}
                  className="form-control form-control-solid h-auto c-selectbox"
                  classNamePrefix="filter-select"
                  onSelected={type => {
                    integrationData.type = type.value
                    placeholder = "example.com"
                    if(type.value === "woocommerce") {
                      integrationData.data.ship_from_country_code = ""
                    }if(type.value === "shopify") {
                      placeholder = "your-shop-name.myshopify.com"
                    }

                    if(type.value !== "woocommerce") {
                      delete integrationData.data.ship_from_country_code
                    }
                    this.setState({placeholder, integrationData})
                  }}
                />
              </div>

              {
                integrationData.type !== "" &&
                <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                  <label className="form-label fw-bolder text-dark required fs-6">{title} Store URL</label>
                  <div className="form-group">
                    <div className="input-group with-prepend">
                      <div className="input-group-prepend" style={{marginRight: -4}}>
                        <span className="form-control form-control-lg form-control-solid">
                          <span>https://</span>
                        </span>
                      </div>
                      <input
                        className="form-control form-control-lg form-control-solid"
                        placeholder={placeholder}
                        value={integrationData.data.shopUrl}
                        onChange={e => {
                          integrationData.data.shopUrl = e.target.value
                          integrationData.data.shop = e.target.value
                          this.setState({integrationData})
                        }}
                      />
                    </div>
                  </div>
                  {
                    integrationData.type === "shopify" &&
                    <div className="text-muted text-start mb-4">
                      If you have connected your Shopify store to a custom domain name,<br/>
                      you will still need to use your original Shopify store URL provided <br/>
                      in order to connect with Taxmatic.
                    </div>
                  }

                  {
                    integrationData.type === "woocommerce" &&
                    <>
                      <div className="fv-row mt-5 fv-plugins-icon-container text-lg-start fv-plugins-bootstrap5-row-valid">
                        <label className="form-label fw-bolder text-dark fs-6">Warehouse Location</label>
                        <div className="form-group">
                          <CountrySelect
                            value={integrationData.data.ship_from_country_code}
                            onSelected={country => {
                              integrationData.data.ship_from_country_code = country.value
                              this.setState({ integrationData })
                            }}
                          />
                        </div>
                      </div>
                      <div className="fv-row mt-5 mb-5 fv-plugins-icon-container text-lg-start fv-plugins-bootstrap5-row-valid">
                        <label className="form-label fw-bolder text-dark fs-6">Do you ship from multiple warehouses?</label>
                        <div className="form-group">
                          <Select
                            value={integrationData.type}
                            disabled={!!integrationData.id}
                            options={[
                              {
                                label: "Yes",
                                value: "yes"
                              },
                              {
                                label: "No",
                                value: "no"
                              }
                            ]}
                            placeholder={"Multiple Warehouses"}
                            getOptionLabel={type => type.label}
                            getOptionValue={type => type.value}
                            className="form-control form-control-solid h-auto c-selectbox"
                            classNamePrefix="filter-select"
                            onSelected={type => {
                              integrationData.multiple_warhouses = type.value
                              if(type.value === "yes") {
                                integrationData.data.warehouses = []
                              }else {
                                delete integrationData.data.warehouses
                              }
                              this.setState({integrationData})
                            }}
                          />
                        </div>
                      </div>
                      {
                        integrationData.multiple_warhouses === "yes" &&
                        <>
                          <div className="fv-row mb-5 fv-plugins-icon-container text-lg-start fv-plugins-bootstrap5-row-valid">
                            <label className="form-label fw-bolder text-dark fs-6">How do you distinguish the ship from location on each order?</label>
                            <div className="form-group">
                              <Select
                                value={integrationData.data.ship_from_evaluation_method}
                                disabled={!!integrationData.id}
                                options={[
                                  {
                                    label: "Order Status",
                                    value: "order_status"
                                  },
                                  {
                                    label: "Via Note / Plugin",
                                    value: "note"
                                  }
                                ]}
                                placeholder={"Identifier"}
                                getOptionLabel={type => type.label}
                                getOptionValue={type => type.value}
                                className="form-control form-control-solid h-auto c-selectbox"
                                classNamePrefix="filter-select"
                                onSelected={type => {
                                  integrationData.data.ship_from_evaluation_method = type.value
                                  this.setState({integrationData})
                                }}
                              />
                            </div>
                          </div>
                          <div className="fv-row fv-plugins-icon-container text-lg-start fv-plugins-bootstrap5-row-valid">
                            <SmartList
                              items={integrationData.data.warehouses}
                              itemName={"Warehouse"}
                              showItemName={true}
                              renderItem={(location, index) => this._renderWarehousePicker(location, index)}
                              onAddItemPressed={() => {
                                integrationData.data.warehouses.push({})
                                this.setState({integrationData})
                              }}
                              onUpdated={(items) => {
                                integrationData.data.warehouses = items
                                this.setState({integrationData})
                              }}
                            />
                          </div>
                        </>
                      }

                    </>
                  }


                </div>
              }

              <div className="text-end pt-10">
                <a
                  className="btn btn-light me-3"
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </a>

                {
                  integrationData.type !== "" &&
                  <a
                    className="btn btn-primary"
                    onClick={() => this._connectStore()}
                  >
                    <span className="indicator-label">
                      Connect
                    </span>
                  </a>
                }

              </div>

            </div>

          </Modal.Body>

        </Modal>
        {
          showShopifyHelp &&
          <ShopifyHelp
            show={showShopifyHelp}
            onHide={() => this.setState({showShopifyHelp: false})}
          />
        }
      </>
    )
  }

}
