import React from 'react'

import Tooltip from '@material-ui/core/Tooltip';

export default class EditCell extends React.Component {
  constructor(props){
    super(props)

    this.state = {

    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {

    } = this.state

    return (
      <Tooltip title={this.props.tooltip} placement="top" arrow>
        <a className="btn btn-icon btn-light btn-hover-primary btn-sm"
           style={{margin: 3}}
           onClick={e => this.props.onEditClicked()}
        >
          <i className="fa la-edit text-primary icon-md"></i>
        </a>
      </Tooltip>
    )
  }
}

EditCell.defaultProps = {
  tooltip: ''
}
