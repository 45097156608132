import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";

export default class BankAccountSetUp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
    }
  }

  componentDidMount() {

  }

  render() {
    let {
      show,
      template,
      templates,
      standardForms,
      authorisedForms
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
          dialogClassName={"modal-dialog modal-dialog-centered mw-650px"}
        >
          <Modal.Header>
            <h2 className="text-center w-100">TAXMATIC</h2>
          </Modal.Header>

          <Modal.Body>

            <p className="text-center w-100 mb-10">
              <b>Setup a Direct Debit with TAXMATIC</b>
            </p>

            <form id="kt_modal_new_card_form" className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">
              <div className="row">
                <div className="col-6">
                  <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                    <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                      <span className="required">First Name</span>
                      <span
                        className="ms-1" data-bs-toggle="tooltip" aria-label="Specify a card holder's name"
                        data-bs-original-title="Specify a card holder's name" data-kt-initialized="1"
                      >
                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                        </i>
												</span>
                    </label>

                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder="First Name"
                      name="card_name"
                    />
                    <div className="fv-plugins-message-container invalid-feedback"></div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                    <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                      <span className="required">Last Name</span>
                      <span
                        className="ms-1" data-bs-toggle="tooltip" aria-label="Specify a card holder's name"
                        data-bs-original-title="Specify a card holder's name" data-kt-initialized="1"
                      >
                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                        </i>
                      </span>
                    </label>

                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder="Last Name"
                      name="card_name"
                    />
                    <div className="fv-plugins-message-container invalid-feedback"></div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Email</label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="Email"
                    name="email"
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Country</label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    placeholder="Select Country"
                    name="country"
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                    <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                      <span className="required">Sort Code</span>

                      <span
                        className="ms-1" data-bs-toggle="tooltip" aria-label="Specify a card holder's name"
                        data-bs-original-title="Specify a card holder's name" data-kt-initialized="1"
                      >
                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                        </i>
                      </span>
                    </label>

                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder="Sort Code"
                      name="card_name"
                    />
                    <div className="fv-plugins-message-container invalid-feedback"></div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                    <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                      <span className="required">Account Number</span>

                      <span
                        className="ms-1" data-bs-toggle="tooltip" aria-label="Specify a card holder's name"
                        data-bs-original-title="Specify a card holder's name" data-kt-initialized="1"
                      >
                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                          <span className="path1"></span>
                          <span className="path2"></span>
                          <span className="path3"></span>
                        </i>
                      </span>
                    </label>

                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder="Account Number"
                      name="card_name"
                    />
                    <div className="fv-plugins-message-container invalid-feedback"></div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-stack">
                <div className="me-5">
                  <div className="fs-7 fw-semibold text-muted">
                    By confirming you agree to GoCardless' <a target="_blank" href="https://gocardless.com">Terms &amp; Conditions</a>
                  </div>
                </div>
              </div>

              <div className="text-end pt-15">
                <button type="reset" id="kt_modal_new_card_cancel" className="btn btn-light me-3">
                  Cancel
                </button>

                <a
                  type="submit"
                  href="payments-connected.html"
                  id="kt_modal_new_card_submit"
                  className="btn btn-primary"
                >
                  <span className="indicator-label">
                    Submit
                  </span>
                </a>
              </div>
            </form>

          </Modal.Body>

        </Modal>
      </>
    )
  }

}