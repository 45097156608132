import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import SignatureCanvas from "react-signature-canvas";

export default class SignaturePad extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      loading: false,
      params: {company: AuthManager.currentUser.company.id}
    }

    this.signaturePad = React.createRef()
  }

  componentDidMount() {

  }

  _saveSignature(){
    let {
      params
    } = this.state

    if(this.signaturePad.current.isEmpty()){
      Notify.error("Please add your signature")
      return
    }

    this.setState({ loading: true })

    General.base64toImageFile(this.signaturePad.current.toDataURL(), "signature.png").then(file => {
      Backend.uploadFile(file, window.Api.Files, params)
        .then(file => {
          this.setState({
            file,
            loading: false
          })
          this.props.onUploaded(file)
        })
        .catch(error => {
          this.setState({ loading: false })
          alert(error.message)
        })
    })
  }

  render() {
    let {
      show,
      loading,
      template,
      templates,
      standardForms,
      authorisedForms
    } = this.state

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
          dialogClassName={"modal-dialog modal-dialog-centered mw-900px"}
        >
          <Modal.Header>
            <h2 className="w-100">Draw your signatue</h2>
          </Modal.Header>

          <Modal.Body className="text-center">

            <SignatureCanvas
              ref={this.signaturePad}
              penColor='black'
              canvasProps={{
                width: 798,
                height: 80,
                className: 'sigCanvas',
                style: { border: "1px solid grey" }
              }}
            />

            <div className="text-end pt-15">
              <a
                className="btn btn-light me-3"
                onClick={() => this.props.onHide()}
              >
                Cancel
              </a>

              <button
                className="btn btn-primary"
                disabled={loading}
                onClick={() => this._saveSignature()}
              >
                <span className="indicator-label">
                  Add
                </span>
              </button>
            </div>

          </Modal.Body>

        </Modal>
      </>
    )
  }

}
