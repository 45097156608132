import React from "react"

import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import Backend from "../../../utils/Backend";
import LocationInput from "../common/LocationInput";
import CountrySelect from "../common/CountrySelect";
import SignaturePad from "../modals/SignaturePad";
import Director from "../modals/Director";
import PhoneInput from "../common/PhoneInput";
import General from "../../../utils/General";
import Email from "../../../utils/Email";

export default class CompanyDetails extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      currentUser: AuthManager.currentUser,
      ...this._getState(props)
    }
  }

  _getState(props){
    return {
      data: {company: props.companyMember?.company.id}
    }
  }

  _isValid(){
    let {
      data,
    } = this.state

    let error = null

    if(!data.registered_name){
      error = "Please enter the company registered name"
    }
    else if(!data.establishment_address){
      error = "Please enter the company establishment address"
    }
    else if(!Email.isValid(data.business_email)){
      error = "Please enter the company email address"
    }
    else if(!data.phone_no || data.phone_no.length <= 6){
      error = "Please enter the company phone number"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _saveCompanyDetails(){
    let {
      data,
      currentUser
    } = this.state

    if(!this._isValid()){
      return
    }

    this.props.onIsLoading(true)

    Backend.updateCompany(currentUser.company.id, data).then(response => {
      Notify.success("Tax Info Saved")
      this.props.onIsLoading(false)
      this.props.onCompanyDetailsSaved()
    }).catch(e => {
      this.props.onIsLoading(false)
      Notify.error(e.message)
    })
  }

  _handlePhoneUpdate(value, phoneData){
    let {
      data
    } = this.state

    if(!phoneData || !phoneData.dialCode){
      return
    }

    data.phone_no = `+${phoneData.dialCode}${value.replace(/[^0-9]+/g, '').slice(phoneData.dialCode.length)}`

    this.setState({
      data
    })
  }

  render() {
    let {
      data,
      hasEORI,
      isLoading,
      showDirector,
      selectedIndex,
      selectedDirector,
      showSignaturePad
    } = this.state

    return (
      <>
        <div className="current" data-kt-stepper-element="content">
          <div className="w-100">
            <div className="pb-10 pb-lg-12">
              <h2 className="fw-bolder text-dark">Company Details</h2>
              <div className="text-muted fw-bold fs-6">If you need more info, please check out
                <a href="https://www.taxmatic.com/contact/" target="_blank" className="link-primary fw-bolder"> Help Page</a>.
              </div>
            </div>

            <div className="tab-content">

              <div className="tab-pane fade yes-vat active show" role="tabpanel">

                <div className="fv-row mb-10">
                  <label className="d-flex align-items-center form-label">
                    <span className="required">Company’s registered name</span>
                  </label>
                  <input
                    name="business_descriptor"
                    className="form-control form-control-lg form-control-solid"
                    value={data.registered_name}
                    onChange={e => {
                      data.registered_name = e.target.value
                      this.setState({data})
                    }}
                  />
                </div>

                <div className="fv-row mb-10">
                  <label className="form-label required">The address of the company in country of establishment</label>
                  <LocationInput
                    className="form-control form-control-lg form-control-solid"
                    location={data.establishment_address || ""}
                    placeholder={""}
                    onBlur={() => null}
                    allowManualInput={true}
                    onUpdated={location => {
                      data.establishment_address = location
                      this.setState({data})
                    }}
                  />
                </div>

                <div className="fv-row mb-10">
                  <label className="d-flex align-items-center form-label">
                    <span className="required">Business Email Address</span>
                  </label>
                  <input
                    name="business_descriptor"
                    className="form-control form-control-lg form-control-solid"
                    value={data.business_email}
                    onChange={e => {
                      data.business_email = e.target.value
                      this.setState({data})
                    }}
                  />
                </div>

                <div className="fv-row mb-10">
                  <label className="d-flex align-items-center form-label">
                    <span className="required">Business Phone (Incl Local Area Code)</span>
                  </label>
                  <PhoneInput
                    value={data.phone_no}
                    enableLongNumbers={true}
                    onChange={(value, data) => this._handlePhoneUpdate(value, data)}
                    inputProps={{ autocomplete: 'off', className: 'form-control form-control-lg form-control-solid' }}
                  />
                </div>

              </div>

            </div>
          </div>
        </div>
        <div className="d-flex flex-stack pt-15">
          <div className="mr-2"/>
          <div>
            <button
              type="button"
              className="btn btn-lg btn-primary"
              data-kt-stepper-action="next"
              onClick={e => this._saveCompanyDetails()}
            >
              Continue
              <span className="svg-icon svg-icon-4 ms-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                        fill="black"></rect>
                  <path
                    d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                    fill="black"></path>
                </svg>
              </span>
            </button>
          </div>
        </div>

      </>
    )
  }

}
