import React from 'react'

import {Link} from "react-router-dom";

import Dropdown from 'react-bootstrap/Dropdown';

import Chart from './Chart'

import Backend from '../../../utils/Backend'
import Currency from '../../../utils/Currency'
import FetchHelper from '../../../utils/FetchHelper'
import General from '../../../utils/General'

export default class Overview extends React.Component  {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      current: "local",
      count: 0,
      filings: []
    }
  }

  componentDidMount(){
    this._setCurrent()
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.company?.id !== this.state.company?.id){
      this.setState(this._getState(nextProps), () => this._setCurrent())
    }
  }

  _getState(props){
    return {
      ...props,
    }
  }

  _setCurrent(){
    let {
    } = this.state

    return Backend.getCurrentFilings()
    .then(response => {
      this.setState({
        filings: response.filings,
        selectedFiling: response.filings[0],
        count: response.filings.length,
      })
    })
  };

  _renderFiling(filing){
    let {
      showDropdown
    } = this.state

    return (
      <div className="row">
        <div className="col-md-4">
          <div className="card card-xl-stretch mb-xl-8">
            <div className="card-body p-0 current-vat-period">
              <div className="px-9 pt-7 card-rounded h-275px w-100 bg-primary">
                <div className="d-flex flex-stack">
                  <h3 className="m-0 text-white fw-bolder fs-3">Current { filing.tax_number.country_code } {filing.type === "local" ? General.toTitleCase(filing.type) : filing.type.toUpperCase()} Period</h3>
                  <div className="ms-1">
                    <Dropdown className="d-inline " autoClose="outside" show={showDropdown} onToggle={() => this.setState({ showDropdown: !showDropdown})}>
                      <Dropdown.Toggle
                        className="btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-primary border-0 me-n3 hide-dropdown"
                        id="dropdown-autoclose-outside"
                        variant={"outline-primary"}
                        onClick={() => this.setState({ showDropdown: true})}
                      >
                        <span className="svg-icon svg-icon-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px" height="24px"
                            viewBox="0 0 24 24"
                          >
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="5" y="5" width="5" height="5" rx="1" fill="#000000"/>
                              <rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3"/>
                              <rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3"/>
                              <rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3"/>
                            </g>
                          </svg>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{width: 120, padding: 10}}
                      >
                        <div className="menu-item ">
                          <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Actions</div>
                        </div>
                        <div className="menu-item ">
                          <Link to={`filing/${filing.id}`} className="menu-link px-3">View</Link>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="d-flex text-center flex-column text-white pt-8">
                  <span className="fw-bold fs-7">NET</span>
                  <span className="fw-bolder fs-2x pt-1">{Currency.format(filing.currency.symbol, filing.net)}</span>
                  <div className="mt-9">
                    <div className="badge badge-lg badge-light-dark d-inline">{ filing.tax_number.country_code } { filing.type.toUpperCase() }</div>
                  </div>
                </div>
              </div>
              <div
                className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1"
                style={{marginTop: -50}}
              >
                <div className="d-flex align-items-center mb-6">
                  <div className="symbol symbol-45px w-40px me-5">
                    <span className="symbol-label bg-lighten">
                      <span className="svg-icon svg-icon-primary svg-icon-2x">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                               width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <rect fill="#000000" x="2" y="5" width="19" height="4" rx="1"/>
                            <rect fill="#000000" opacity="0.3" x="2" y="11" width="19" height="10" rx="1"/>
                          </g>
                        </svg>
                      </span>
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-wrap w-100">
                    <div className="mb-1 pe-3 flex-grow-1">
                      <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bolder">Tax Period</a>
                      <div className="text-gray-400 fw-bold fs-7">{filing.transactions_no} Transactions</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="fw-bolder fs-5 text-gray-800 pe-1">{filing.period}</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-6">
                  <div className="symbol symbol-45px w-40px me-5">
                    <span className="symbol-label bg-lighten">
                      <span className="svg-icon svg-icon-primary svg-icon-2x">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                             width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path
                              d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z"
                              fill="#000000" fillRule="nonzero" opacity="0.3"
                            />
                            <path
                              d="M3.28077641,9 L20.7192236,9 C21.2715083,9 21.7192236,9.44771525 21.7192236,10 C21.7192236,10.0817618 21.7091962,10.163215 21.6893661,10.2425356 L19.5680983,18.7276069 C19.234223,20.0631079 18.0342737,21 16.6576708,21 L7.34232922,21 C5.96572629,21 4.76577697,20.0631079 4.43190172,18.7276069 L2.31063391,10.2425356 C2.17668518,9.70674072 2.50244587,9.16380623 3.03824078,9.0298575 C3.11756139,9.01002735 3.1990146,9 3.28077641,9 Z M12,12 C11.4477153,12 11,12.4477153 11,13 L11,17 C11,17.5522847 11.4477153,18 12,18 C12.5522847,18 13,17.5522847 13,17 L13,13 C13,12.4477153 12.5522847,12 12,12 Z M6.96472382,12.1362967 C6.43125772,12.2792385 6.11467523,12.8275755 6.25761704,13.3610416 L7.29289322,17.2247449 C7.43583503,17.758211 7.98417199,18.0747935 8.51763809,17.9318517 C9.05110419,17.7889098 9.36768668,17.2405729 9.22474487,16.7071068 L8.18946869,12.8434035 C8.04652688,12.3099374 7.49818992,11.9933549 6.96472382,12.1362967 Z M17.0352762,12.1362967 C16.5018101,11.9933549 15.9534731,12.3099374 15.8105313,12.8434035 L14.7752551,16.7071068 C14.6323133,17.2405729 14.9488958,17.7889098 15.4823619,17.9318517 C16.015828,18.0747935 16.564165,17.758211 16.7071068,17.2247449 L17.742383,13.3610416 C17.8853248,12.8275755 17.5687423,12.2792385 17.0352762,12.1362967 Z"
                              fill="#000000"
                            />
                          </g>
                        </svg>
                      </span>
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-wrap w-100">
                    <div className="mb-1 pe-3 flex-grow-1">
                      <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bolder">Tax On Sales</a>
                      <div className="text-gray-400 fw-bold fs-7">{filing.sales_no} Sales</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="fw-bolder fs-5 text-gray-800 pe-1">{Currency.format(filing.currency.symbol, filing.sales_tax)}</div>
                      <span className="svg-icon svg-icon-5 svg-icon-success ms-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none">
                          <rect
                            opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black"
                          />
                          <path
                            d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-6">
                  <div className="symbol symbol-45px w-40px me-5">
                    <span className="symbol-label bg-lighten">
                      <span className="svg-icon svg-icon-primary svg-icon-2x">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                             width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                            <path
                              d="M3.52270623,14.028695 C2.82576459,13.3275941 2.82576459,12.19529 3.52270623,11.4941891 L11.6127629,3.54050571 C11.9489429,3.20999263 12.401513,3.0247814 12.8729533,3.0247814 L19.3274172,3.0247814 C20.3201611,3.0247814 21.124939,3.82955935 21.124939,4.82230326 L21.124939,11.2583059 C21.124939,11.7406659 20.9310733,12.2027862 20.5869271,12.5407722 L12.5103155,20.4728108 C12.1731575,20.8103442 11.7156477,21 11.2385688,21 C10.7614899,21 10.3039801,20.8103442 9.9668221,20.4728108 L3.52270623,14.028695 Z M16.9307214,9.01652093 C17.9234653,9.01652093 18.7282432,8.21174298 18.7282432,7.21899907 C18.7282432,6.22625516 17.9234653,5.42147721 16.9307214,5.42147721 C15.9379775,5.42147721 15.1331995,6.22625516 15.1331995,7.21899907 C15.1331995,8.21174298 15.9379775,9.01652093 16.9307214,9.01652093 Z"
                              fill="#000000" fillRule="nonzero" opacity="0.8"></path>
                          </g>
                        </svg>
                      </span>
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-wrap w-100">
                    <div className="mb-1 pe-3 flex-grow-1">
                      <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bolder">Tax On Purchases</a>
                      <div className="text-gray-400 fw-bold fs-7">{filing.purchases_no} Purchases</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="fw-bolder fs-5 text-gray-800 pe-1">{Currency.format(filing.currency.symbol, filing.purchases_tax)}</div>
                      <span className="svg-icon svg-icon-5 svg-icon-danger ms-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none">
                          <rect
                            opacity="0.5" x="11" y="18" width="13" height="2" rx="1"
                            transform="rotate(-90 11 18)" fill="black"
                          />
                          <path
                            d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card card-xl-stretch mb-5 mb-xl-8">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3 mb-1">Recent Filings</span>
                <span className="text-muted fw-bold fs-7">{this.state.count} filing{this.state.count > 1 ? 's' : ''} made</span>
              </h3>
            </div>
            <div className="card-body h-450px" style={{position: 'relative'}}>
              <Chart filing={filing} updateCount={count => this.setState({count})}/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render(){
    let { filings, selectedFiling } = this.state

    if(!selectedFiling){
      return null
    }

    return (
      <>
        <div id="kt_app_toolbar" className="app-toolbar  py-3 py-lg-6 ">
          <div id="kt_app_toolbar_container" className="app-container  container-xxl d-flex flex-stack ">
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 "></div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
              <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold ">
                {
                  filings.map(filing => {
                    let className = selectedFiling.id === filing.id ? `text-active-primary active` : ""
                    let label = `${filing.tax_number.country_code} ${General.toTitleCase(filing.type)}`
                    if(filing.type === "oss" || filing.type === "ioss"){
                      label = `${filing.type.toUpperCase()}`
                    }
                    return (
                      <li className="nav-item">
                        <a
                          className={`nav-link pb-4 ${className}`}
                          onClick={e => {
                            this.setState({selectedFiling: filing})
                          }}
                        >
                          {label}
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>

        <div className="tab-content" id="myTabContent">
          <div className={`tab-pane fade show active`} id="kt_local_filing_tab" role="tabpanel">
            { this._renderFiling(selectedFiling) }
          </div>
        </div>
      </>
    )
  }
}

Overview.defaultProps = {

}
