import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import SignatureCanvas from "react-signature-canvas";
import LocationInput from "../common/LocationInput";
import FileUploader from "../common/FileUploader";
import Dropzone from "../common/Dropzone";

export default class Director extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      selectedIndex: props.selectedIndex,
      director: props.director || {}
    }
  }

  componentDidMount() {

  }

  _isValid(){
    let {
      director
    } = this.state

    let error = null

    if (!director.first_name){
      error = "Please enter the director's first name"
    }else if (!director.last_name){
      error = "Please enter the director's first name"
    }else if (!director.address){
      error = "Please enter the director's address"
    }else if (!director.passport_photo){
      error = "Please upload the director's passport photo"
    }else if (!director.shareholding){
      error = "Please enter the director's shareholding percentage"
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _onAddClick(){
    let {
      director
    } = this.state

    if(!this._isValid()){
      return
    }

    this.props.onAdded(director)
  }

  render() {
    let {
      show,
      director,
      selectedIndex,
      standardForms,
      authorisedForms
    } = this.state

    let buttonTitle = this.props.director ? 'Update' : 'Add'

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
          dialogClassName={"modal-dialog modal-dialog-centered mw-550px"}
        >
          <Modal.Header>
            <h2 className="w-100">Director</h2>
          </Modal.Header>

          <Modal.Body>

            <div className="form fv-plugins-bootstrap5 fv-plugins-framework">
              <div className="row">
                <div className="col-6">
                  <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                    <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                      <span className="required">First Name</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder="First Name"
                      value={director.first_name}
                      onChange={e => {
                        director.first_name = e.target.value
                        this.setState({director})
                      }}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                    <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                      <span className="required">Last Name</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder="Last Name"
                      value={director.last_name}
                      onChange={e => {
                        director.last_name = e.target.value
                        this.setState({director})
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Address</label>
                <div className="position-relative">
                  <LocationInput
                    className="form-control form-control-lg form-control-solid"
                    location={director.address || ""}
                    placeholder={""}
                    onBlur={() => null}
                    allowManualInput={true}
                    onUpdated={location => {
                      director.address = location
                      this.setState({director})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                  <span className="required">Shareholding (Percentage)</span>
                </label>
                <input
                  type="number"
                  className="form-control form-control-solid"
                  placeholder="Shareholding"
                  min={0}
                  max={100}
                  step={0.1}
                  value={director.shareholding}
                  onChange={e => {
                    let { value, min, max } = e.target;
                    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
                    director.shareholding = value
                    this.setState({director})
                  }}
                />
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Passport Photo</label>
                <div className="position-relative">
                  <Dropzone
                    accept="image/jpg,image/jpeg,image/png,application/pdf"
                    endpoint={window.Api.Files}
                    type={"document"}
                    value={director.passport_photo}
                    onUploaded={file => {
                      director.passport_photo = file
                      this.setState({ director })
                    }}
                    onUploadStarted={() => {
                      this.setState({ loading: true })
                    }}
                    onUploadFinished={() => {
                      this.setState({ loading: false })
                    }}
                    onRemoved={() => {
                      director.passport_photo = null
                      this.setState({ director })
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="text-end pt-15">
                <a
                  className="btn btn-light me-3"
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </a>

                <a
                  className="btn btn-primary"
                  onClick={() => this._onAddClick()}
                >
                  <span className="indicator-label">
                    {buttonTitle}
                  </span>
                </a>
              </div>

            </div>

          </Modal.Body>

        </Modal>
      </>
    )
  }

}
