import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import LocationInput from "../common/LocationInput";
import FileUploader from "../common/FileUploader";
import Dropzone from "../common/Dropzone";
import CountrySelect from "../common/CountrySelect";
import Select from "../common/Select";
import AsyncSelect from "../common/AsyncSelect";

export default class Tax extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      selectedIndex: props.selectedIndex,
      tax: props.tax || {country_code: null}
    }
  }

  componentDidMount() {

  }

  _isValid(){
    let {
      tax
    } = this.state

    let error = null

    if (!tax.type){
      error = "Please select the TAX type"
    }else if (!tax.tax_no){
      error = "Please enter the tax no"
    }

    if(!error){
      if (tax.type === "local" && !tax.country_code){
        error = "Please select the country"
      }
    }

    if(error){
      Notify.error(error)
      return false
    }

    return true
  }

  _onAddClick(){
    let {
      tax
    } = this.state

    if(!this._isValid()){
      return
    }

    if(this.props.onAdded){
      this.props.onAdded(tax)
    }else{
      this.setState({loading: true})

      this._handleBackend(tax).then(response => {
        this.setState({loading: false})
        Notify.success("Tax Number Saved")
        this.props.onSuccess()
      }).catch(e => {
        this.setState({loading: false})
        Notify.error(e.message)
      })
    }
  }

  _handleBackend(tax){
    if(tax.id){
      return Backend.updateTaxNumber(tax)
    }
    else{
      return Backend.addTaxNumber(tax)
    }
  }

  render() {
    let {
      show,
      tax,
      loading,
      standardForms,
      authorisedForms
    } = this.state

    let buttonTitle = this.props.tax ? 'Update' : 'Add'

    let currency = tax.currency ? {value: tax.currency.id, label: tax.currency.name, data: tax.currency} : null

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
          dialogClassName={"modal-dialog modal-dialog-centered mw-550px"}
        >
          <Modal.Header>
            <h2 className="w-100">Tax Number</h2>
          </Modal.Header>

          <Modal.Body>

            <div className="form fv-plugins-bootstrap5 fv-plugins-framework">

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">Registration Type</label>
                <div className="position-relative">
                  <Select
                    value={tax.type}
                    disabled={!!tax.id}
                    options={[
                      {
                        label: "Local",
                        value: "local"
                      },
                      {
                        label: "OSS",
                        value: "oss"
                      },
                      {
                        label: "IOSS",
                        value: "ioss"
                      }
                    ]}
                    placeholder={"Type"}
                    getOptionLabel={type => type.label}
                    getOptionValue={type => type.value}
                    className="form-control form-control-solid h-auto c-selectbox"
                    classNamePrefix="filter-select"
                    onSelected={type => {
                      tax.type = type.value
                      if(type.value !== "local") {
                        tax.country_code = null
                      }
                      this.setState({tax})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="required fs-6 fw-semibold form-label mb-2">TAX Number</label>
                <div className="position-relative">
                  <input
                    type="text"
                    value={tax.tax_no}
                    placeholder="TAX Number"
                    className="form-control form-control-solid"
                    onChange={e => {
                      tax.tax_no = e.target.value
                      this.setState({tax})
                    }}
                  />
                </div>
                <div className="fv-plugins-message-container invalid-feedback"></div>
              </div>

              {
                tax.type === "local" &&
                <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                  <label className="required fs-6 fw-semibold form-label mb-2">Country</label>
                  <div className="position-relative">
                    <CountrySelect
                      disabled={!!tax.id}
                      isEU={true}
                      value={tax.country_code}
                      onSelected={country => {
                        tax.country_code = country.value
                        this.setState({tax})
                      }}
                    />
                  </div>
                </div>
              }

              <div className="text-end pt-15">
                <a
                  className="btn btn-light me-3"
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </a>

                <button
                  className="btn btn-primary"
                  onClick={() => this._onAddClick()}
                  disabled={loading}
                >
                  <span className="indicator-label">
                    {buttonTitle}
                  </span>
                </button>
              </div>

            </div>

          </Modal.Body>

        </Modal>
      </>
    )
  }

}
