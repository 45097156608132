import React from 'react'
import Backend from "../../../utils/Backend";
import AuthManager from "../../../utils/AuthManager";
import Notify from "../../../utils/Notify";

export default class ProblematicProducts extends React.Component  {
  constructor(props){
    super(props)

    this.state = {
      isLoading: false,
      integration: props.integration,
      problematicProducts: []
    }
  }

  componentDidMount() {
    this._getProblematicProducts()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps, () => this._getProblematicProducts())
  }

  _getProblematicProducts(){
    if(!this.state.integration) return
    this.setState({isLoading:true})
    Backend.getProblematicProducts(this.state.integration.id, AuthManager.currentUser.company.id).then(response => {
      this.setState({problematicProducts: response.results, isLoading: false}, () => {
        if(response.results.length < 1){
          this.props.onEmpty()
        }
      })
    }).catch(e => {
      this.setState({isLoading: false})
      Notify.error(e.message)
    })
  }

  _renderProducts(){
    let {
      problematicProducts
    } = this.state

    return (
      <>
        {
          problematicProducts.map(problematicProduct => {
            return (
              <>
                <div className="d-flex mb-7">
                  <div className="symbol symbol-60px symbol-2by3 flex-shrink-0 me-4">
                    <img src={problematicProduct.image?.original} className="mw-100" alt={problematicProduct.title}/>
                  </div>

                  <div className="d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1">
                    <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                      <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bolder">
                        {problematicProduct.title}
                      </a>
                      <div>
                        <a className="badge badge-light-warning fw-bold fs-7 my-1">Missing HS Code</a>
                      </div>
                    </div>
                    <div className="text-end py-lg-0 py-2">
                      <a target="_blank" href={problematicProduct.external_url}
                         className="badge badge-light-primary fw-boldest fs-7">Update</a>
                    </div>
                  </div>
                </div>
              </>
            )
          })
        }
      </>
    )
  }

  _renderPlaceHolders(){

    return (
      <>
        {
          Array.from([1,2,3,4,5]).map(problematicProduct => {
            return (
              <>
                <div className="d-flex mb-7">
                  <div className="symbol shimmer symbol-60px symbol-2by3 flex-shrink-0 me-4"/>

                  <div className="d-flex shimmer align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1"/>
                </div>
              </>
            )
          })
        }
      </>
    )
  }

  render(){
    let {
      isLoading,
      integration,
      problematicProducts
    } = this.state

    if(!integration) return null

    return (
      <div className="card card-xxl-stretch" style={{minHeight: 525, maxHeight: 525}}>
        <div className="card-header align-items-center border-0 mt-3">
          <h3 className="card-title align-items-start flex-column">
            <span className="fw-bolder text-dark fs-3">Problematic Products</span>
            <span className="text-gray-400 mt-2 fw-bold fs-6">{problematicProducts.length} Products With Issues</span>
          </h3>
        </div>

        <div className="card-body pt-5" style={{overflow: 'scroll'}}>
          {
            isLoading ?
              this._renderPlaceHolders()
            :
              this._renderProducts()
          }

        </div>
      </div>
    )
  }
}
