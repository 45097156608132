import React from 'react'

import AsyncStorage from './AsyncStorage'
import FetchHelper from './FetchHelper'

import jwtDecode from 'jwt-decode'
import moment from 'moment'

var isLoggedIn = false
var accessToken = null
var refreshToken = null
var currentUser = null

var cachedValidateTokensFunction = null

const KEY_ACCESS_TOKEN = 'accessToken'
const KEY_REFRESH_TOKEN = 'refreshToken'

export default class AuthManager {
  static isAuthenticated() {
    return AuthManager.isLoggedIn
  }

  static getAccessToken() {
    return AuthManager.accessToken
  }

  static getCurrentUser() {
    return AuthManager.currentUser
  }

  static isPending() {
    return AuthManager.currentUser.user.email != "shopify-test@taxmatic.com"
  }

  static _hasError(responseJson) {
    let hasError = false
    let tokens = responseJson.tokens

    if (!tokens) {
      hasError = true
    }

    if (tokens.length === 0) {
      hasError = true
    }

    if (!tokens.access) {
      hasError = true
    }

    if (!tokens.refresh) {
      hasError = true
    }

    return hasError
  }

  static register(data) {
    return FetchHelper.post(window.Api.Companies, data, false, false)
      .then(responseJson => {
        if (this._hasError(responseJson)) {
          throw AuthManager.getError(responseJson)
        }
        AuthManager._updateTokens(responseJson.tokens)
        AuthManager._setUser(responseJson)
        return responseJson
      })
      .catch(error => {
        throw AuthManager.getError(error)
      })
  }

  static login(email, password) {
    let data = { email, password }

    return FetchHelper.post(window.Api.User.Login, data, false, false)
      .then(responseJson => {
        return AuthManager._handleLoginResponse(responseJson)
      })
      .catch(error => {
        throw AuthManager.getError(error)
      })
  }

  static verifyLogin(ephemeral_token, code) {

    let data = {ephemeral_token, code}

    return FetchHelper.post(`${window.Api.User.Login}/code`, data, false, false)
      .then(responseJson => {
        return AuthManager._handleLoginResponse(responseJson)
      })
      .catch(error => {
        throw AuthManager.getError(error)
      })
  }

  static _handleLoginResponse(responseJson){
    return new Promise((resolve, reject) => {
      if(responseJson.ephemeral_token) {
        throw responseJson
      }

      if (this._hasError(responseJson)) {
        throw AuthManager.getError(responseJson)
      }
      if(!responseJson.company_member){
        throw {error:"invalid user", message:"Only company members can access this"}
      }
      AuthManager._updateTokens(responseJson.tokens)
      AuthManager._setUser(responseJson)

      return resolve(responseJson)
    })
  }

  static _getMinutesUntilTokenExpiration() {
    var decodedJWT = jwtDecode(AuthManager.accessToken)
    var exp = decodedJWT.exp * 1000
    var expirationTime = moment(exp)
    var today = moment()
    return expirationTime.diff(today, 'minutes')
  }

  static validateTokens() {
    return AuthManager.getCachedValidateTokensFunction()
  }

  static _validateTokens() {

    let remainingMinutes = AuthManager._getMinutesUntilTokenExpiration()
    if (remainingMinutes > 1) {
      setTimeout(() => {
        AuthManager.clearCachedValidateTokensFunction()
      }, 300)
      return new Promise((resolve, reject) => resolve())
    }

    return AuthManager.refreshTokens()
      .then(() => {
        AuthManager.clearCachedValidateTokensFunction()
        return true
      }).catch((error) => {
        AuthManager.clearCachedValidateTokensFunction()
        // Token has expired.
        window.location.href = '/login'
      })
  }

  static refreshTokens() {
    return AsyncStorage.getItem(KEY_REFRESH_TOKEN)
      .then(refreshToken => {
        if (!refreshToken) {
          throw { message: 'No Refresh Token Found' }
          return
        }
        // try and refresh the token if we find it, if this fails
        // our token has expired and we will need user to re login
        // manually
        const data = { refresh: refreshToken }

        return FetchHelper.post(window.Api.User.RefreshToken, data, false, false)
      })
      .then(tokenResponse => {
        return AuthManager._updateTokens(tokenResponse)
      })
  }

  static silentLogin() {
    return AuthManager.refreshTokens()
      .then(() => {
        return FetchHelper.get(window.Api.User.Info)
      })
      .then(responseJson => {
        AuthManager._setUser(responseJson)
        return AuthManager.currentUser
      })
      .catch(error => {
        AuthManager.accessToken = null
        AuthManager.refreshToken = null
        throw error
      })
  }

  static async logout() {
    let data = { refresh: AuthManager.refreshToken }
    return FetchHelper.post(window.Api.User.Logout, data).then(() => {
      return AuthManager.removeCredentials()
    })
  }

  static requestResetPassword(email) {
    return FetchHelper.post(
      window.Api.User.RequestResetPassword,
      {
        email
      },
      false,
      false
    )
  }

  static resetPassword(email, password, code) {
    let data = {
      email,
      password,
      verification_code: code
    }
    return FetchHelper.post(window.Api.User.ResetPassword, data, false, false)
  }

  static removeCredentials() {
    AuthManager.accessToken = null
    AuthManager.refreshToken = null
    AuthManager.isLoggedIn = false
    AuthManager.currentUser = null
    AsyncStorage.removeItem(KEY_ACCESS_TOKEN)
    return AsyncStorage.removeItem(KEY_REFRESH_TOKEN)
  }

  static getError(error) {
    var errorMessage = 'An unexpected error occurred'

    if (error.email) {
      errorMessage = error.email[0]
    } else if (error.message) {
      errorMessage = error.message
    } else if (error.non_field_errors) {
      errorMessage = error.non_field_errors[0]
    } else if (error.detail) {
      errorMessage = error.detail
    } else if(error.ephemeral_token) {
      errorMessage = error
    }
    return { error: errorMessage, message: errorMessage }
  }

  static _updateTokens(tokens) {
    AuthManager.accessToken = tokens.access
    AuthManager.refreshToken = tokens.refresh
    AsyncStorage.setItem(KEY_ACCESS_TOKEN, AuthManager.accessToken)
    AsyncStorage.setItem(KEY_REFRESH_TOKEN, AuthManager.refreshToken)
  }

  static _setUser(responseJson) {
    AuthManager.isLoggedIn = true
    AuthManager.currentUser = this._getCurrentUser(responseJson)
  }

  static _getCurrentUser(responseJson) {
    if (responseJson.company_member) {
      return responseJson.company_member
    }
    return null
  }

  static getHeaders(contentType = 'application/json', authenticate = true) {
    var headers = {}
    if (contentType === 'application/json') {
      headers = { 'Content-Type': contentType }
    }
    if (authenticate && AuthManager.accessToken) {
      headers['Authorization'] = 'Bearer ' + AuthManager.accessToken
    }

    return new Headers(headers)
  }

  /*** Multi Factor Authentication ***/
  static getActiveMethods() {
    return FetchHelper.get(`${window.Api.MFA}/active-methods`);
  }

  static activateMethod(method) {
    return FetchHelper.post(`${window.Api.MFA}/${method}/activate`);
  }

  static activateCode(method, code) {
    let data = {
      code,
    };

    return FetchHelper.post(
      `${window.Api.MFA}/${method}/activate/confirm`,
      data
    )
      .then(response => response.backup_codes)
  }

  static resendCode(method, email, ephemeral_token) {
    let data = { method, email, ephemeral_token };
    return FetchHelper.post(`${window.Api.MFA}/code/resend`, data, false, false);
  }

  static requestCode(method) {
    let data = { method };
    return FetchHelper.post(`${window.Api.MFA}/code/request`, data);
  }

  static deactivateCode(method, code) {
    let data = {
      code,
    };

    return FetchHelper.post(`${window.Api.MFA}/${method}/deactivate`, data);
  }

  static regenerateBackupCodes(code) {
    let data = {
      code,
    };

    return FetchHelper.post(`${window.Api.MFA}/backup-codes/regenerate`, data)
      .then(response => response.backup_codes)
  }


  static setDefaultMethod(method, code){
    let data = { method, code };
    return FetchHelper.post(`${window.Api.MFA}/change-primary-method`, data);
  }


  static getCachedValidateTokensFunction(){
    if(!AuthManager.cachedValidateTokensFunction){
      AuthManager.cachedValidateTokensFunction = AuthManager._validateTokens()
    }
    return AuthManager.cachedValidateTokensFunction
  }

  static clearCachedValidateTokensFunction(){
    AuthManager.cachedValidateTokensFunction = undefined
  }
}
