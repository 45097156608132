import React from "react"
import moment from "moment";

import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Event from "../utils/Event";
import {Link} from "react-router-dom";
import BankAccountSetUp from "./components/modals/BankAccountSetUp";
import PaymentsTable from "./components/tables/Payments";
import AsyncStorage from "../utils/AsyncStorage";
import General from "../utils/General";
import Currency from "../utils/Currency";
import Invoices from "./components/tables/Invoices";
import Permissions from "../utils/Permissions";

export default class Subscription extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser,
      company: AuthManager.currentUser.company,
    }
  }

  componentDidMount() {
    let {
      currentUser
    } = this.state

    let status = General.getUrlParameter("status")

    if(status === "success"){
      this.setState({isLoading: true})
      AsyncStorage.getItem('gocardless_data').then(goCardlessData => {
        let data = {
          type: 'gocardless',
          state: goCardlessData
        }

        Backend.addIntegration(data).then(response => {
          Notify.success("Go Cardless Connected")
          AsyncStorage.removeItem('gocardless_data')
          currentUser.company.subscription.payment_instrument = true
          this.setState({currentUser, isLoading: false})
        })
      })
    }else if(status === "failure"){
      Notify.error("Failed to connect GoCardless, please try again.")
    }

    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search)

    params.delete("status")
    let pathName = window.location.pathname
    if(params.toString()){
      pathName += "?" + params.toString()
    }
    window.history.replaceState(null, window.document.title, pathName)
  }

  _connectGoCardless(){
    let {
      isLoading,
    } = this.state

    if(isLoading) return

    let data = {
      company: AuthManager.currentUser.company.id,
      redirect_url: window.location.href,
      type: "gocardless"
    }

    this.setState({isLoading: true})

    Backend.addIntegration(data).then(response => {
      AsyncStorage.setItem('gocardless_data', response.state)
      window.location = response.redirect_url
    }).catch(e => {
      this.setState({isLoading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      company,
      isLoading,
      currentUser,
      showBankAccountForm
    } = this.state

    let bankAccountConnected = !!company.subscription.payment_instrument

    return (
      <div className="overlay">

        {
          (!bankAccountConnected && Permissions.hasCreatePermission(Permissions.PAYMENTS)) &&
          <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
            <i
              className="ki-duotone ki-information fs-2tx text-warning me-4"
            >
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </i>
            <div className="d-flex flex-stack flex-grow-1 ">
              <div className=" fw-semibold">
                <h4 className="text-gray-900 fw-bold">We need your attention!</h4>
                <div className="fs-6 text-gray-700 ">Your bank account has not been connected. Please
                  <a
                    href="#"
                    className="fw-bold"
                    onClick={() => this._connectGoCardless()}
                  > Connect Your Bank Account
                  </a>.
                </div>
              </div>
            </div>
          </div>
        }

        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">

          <div className="card-header cursor-pointer">

            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Subscription Details</h3>
            </div>

          </div>


          <div className="card-body p-9">

            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Frequency</label>


              <div className="col-lg-8 fv-row">
                <span className="fw-bold text-gray-800 fs-6">{General.toTitleCase(company.subscription?.frequency || '')}</span>
              </div>

            </div>


            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Price</label>


              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 text-gray-800 me-2">{Currency.format(company.subscription?.currency.symbol, company.subscription?.total)}</span>
              </div>

            </div>

            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Included Transactions Threshold</label>


              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 text-gray-800 me-2">{company.subscription?.included_transactions_no}</span>
              </div>

            </div>

            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Charge Per Transaction Over Threshold</label>


              <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 text-gray-800 me-2">{Currency.display(company.subscription?.currency.symbol, parseFloat(company.subscription?.transaction_threshold_charge))}</span>
              </div>

            </div>


            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Free Trial End Date</label>


              <div className="col-lg-8">
                <a href="#" className="fw-bold fs-6 text-gray-800 text-hover-primary">{company.subscription?.free_trial_ends_at ? moment(company.subscription?.free_trial_ends_at).format('DD-MM-YYYY') : '-'}</a>
              </div>

            </div>


            <div className="row mb-7">

              <label className="col-lg-4 fw-bold text-muted">Next Invoice Date</label>


              <div className="col-lg-8">
                <a href="#" className="fw-bold fs-6 text-gray-800 text-hover-primary">{moment(company.subscription?.next_invoice_at).format('DD-MM-YYYY')}</a>
              </div>

            </div>

          </div>

        </div>

        {
          company.subscription.on_platform &&
          <Invoices/>
        }
      </div>
    )
  }

}
