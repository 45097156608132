import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import SignatureCanvas from "react-signature-canvas";
import LocationInput from "../common/LocationInput";
import FileUploader from "../common/FileUploader";
import ShopifyHelp from "./ShopifyHelp";

export default class ShopifyDetails extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      shopifyData: {
        company: AuthManager.currentUser.company.id,
        redirect_url: window.location.href,
        type: "shopify",
        data: {}
      }
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _connectShopify(){
    let {
      shopifyData
    } = this.state

    if(!shopifyData.data.shop){
      Notify.error("Please enter your Shopify shop url")
      return
    }else if(!shopifyData.data.shop.includes('.myshopify.com')){
      Notify.error("Please enter a valid Shopify shop url")
      return
    }

    if(!shopifyData.data.shop.startsWith('http')){
      shopifyData.data.shop = 'https://' + shopifyData.data.shop
    }

    this.setState({isLoading: true})

    Backend.addIntegration(shopifyData).then(response => {
      window.location = response.redirect_url
    }).catch(e => {
      this.setState({isLoading: false})
      Notify.error(e.message)
    })
  }

  render() {
    let {
      show,
      isLoading,
      shopifyData,
      showShopifyHelp,
    } = this.state

    let className = ""
    if(isLoading){
      className = "overlay overlay-block"
    }

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
          dialogClassName={`modal-dialog modal-dialog-centered mw-550px ${className}`}
        >
          <Modal.Header>
            <h2 className="w-80 d-flex">Connect Shopify Account</h2>
            <div className="text-muted fw-bold fs-6 w-20">
              <a
                href="#"
                className="text-primary fw-bolder"
                onClick={() => {
                  this.setState({showShopifyHelp: true})
                }}
              >
                Help Page
              </a>
            </div>
          </Modal.Header>

          <Modal.Body>

            <div className="form fv-plugins-bootstrap5 fv-plugins-framework">
              <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                <label className="form-label fw-bolder text-dark fs-6">Shopify Store URL</label>
                <div className="form-group">
                  <div className="input-group with-prepend">
                    <div className="input-group-prepend" style={{marginRight: -4}}>
                      <span className="form-control form-control-lg form-control-solid">
                        <span>https://</span>
                      </span>
                    </div>
                    <input
                      className="form-control form-control-lg form-control-solid"
                      placeholder="your-shop-name.myshopify.com"
                      value={shopifyData.data.shopUrl}
                      onChange={e => {
                        shopifyData.data.shopUrl = e.target.value
                        shopifyData.data.shop = e.target.value
                        this.setState({shopifyData})
                      }}
                    />
                  </div>
                </div>
                <div className="fv-plugins-message-container invalid-feedback"/>
                <div className="text-muted text-start mb-4">
                  If you have connected your Shopify store to a custom domain name,<br/>
                  you will still need to use your original Shopify store URL provided <br/>
                  in order to connect with Taxmatic.
                </div>
              </div>

              <div className="text-end pt-15">
                <a
                  className="btn btn-light me-3"
                  onClick={() => this.props.onHide()}
                >
                  Cancel
                </a>

                <a
                  className="btn btn-primary"
                  onClick={() => this._connectShopify()}
                >
                  <span className="indicator-label">
                    Connect
                  </span>
                </a>
              </div>

            </div>

          </Modal.Body>

        </Modal>
        {
          showShopifyHelp &&
          <ShopifyHelp
            show={showShopifyHelp}
            onHide={() => this.setState({showShopifyHelp: false})}
          />
        }
      </>
    )
  }

}
