import React from 'react'

import AuthManager from '../utils/AuthManager'

import RolesTable from './components/tables/RolesTable'
import TaxDetails from "./components/tables/TaxNumbers";

export default class TaxNumbers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <>
        <TaxDetails title={'Tax Numbers'} />
      </>
    )
  }
}
