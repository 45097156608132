import React from "react"

import {Link} from "react-router-dom";

import Stats from "./components/Stats";
import Transactions from "./components/tables/Transactions";

import FilingOverview from "./components/filings/Overview";

import General from "../utils/General";
import AuthManager from "../utils/AuthManager";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Event from "../utils/Event";


export default class Dashboard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentUser: AuthManager.currentUser
    }
  }

  componentDidMount() {
    let onboardingStatus = this.state.currentUser.company.info.status
    if(onboardingStatus !== "complete"){
      window.location = "/register"
    }
  }

  _renderPlaceholder(){
    return (
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-body d-flex p-0">
          <div
            className="flex-grow-1 p-20 pb-lg-20 card-rounded h-350px flex-grow-1 bgi-no-repeat"
            style={{
              backgroundColor: '#0ea5e9',
              backgroundPosition: 'calc(100% + 0.5rem) bottom',
              backgroundSize: '25% auto',
              backgroundImage: `url(${require('../assets/media/svg/humans/custom-10.svg').default})`
            }}
          >

            <h1 className="text-white pt-10 pb-5 font-weight-bolder" style={{fontSize: 40}}>
              Congrats!
            </h1>

            <p className="text-white pb-5" style={{fontSize: 18}}>
              You've successfully connected your Shopify account<br/>
              Our team will reach out to you in the coming days with next steps<br/>
            </p>

          </div>
        </div>
      </div>
    )
  }

  render() {
    let {
      stats
    } = this.state

    return (
      <>

        <FilingOverview/>

        <Stats/>

        <div className="row">
          <div className="col-sm-12 col-xl-12">
            <Transactions
              latestResults={true}
            />
          </div>
        </div>
      </>
    )
  }

}
