import Calculations from "../../../utils/Calculations";
import React from "react";

export default class LineItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...this._getState(props),
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      editable: props.editable,
      lineItem: props.lineItem,
    }
  }

  render() {
    let {
      record,
      editable,
      lineItem
    } = this.state

    return (
      <>
        <tr>
          <td>
            <textarea
              className="form-control form-control-solid mb-3 mb-lg-0"
              style={{width: 140}}
              rows={1}
              disabled={editable}
              value={lineItem.description}
              onChange={e => {
                lineItem.description = e.target.value
                this.props.onUpdated(lineItem)
              }}
            />
          </td>
          <td>
            <input
              type="number"
              className="form-control form-control-solid mb-3 mb-lg-0"
              style={{width: 100}}
              step={"0.01"}
              disabled={editable}
              value={lineItem.subtotal}
              onChange={e => {
                lineItem.subtotal = e.target.value
                this.props.onUpdated(lineItem)
              }}
            />
          </td>
          <td>
            <input
              type="number"
              className="form-control form-control-solid mb-3 mb-lg-0"
              style={{width: 100}}
              disabled={editable}
              value={lineItem.taxes[0]?.rate * 100 || lineItem.taxes[0].tax_rate.rate * 100}
              onChange={e => {
                let taxRate = parseFloat(e.target.value/100)
                lineItem.taxes[0] = {tax: Calculations.totalVat(lineItem.quantity, lineItem.subtotal, taxRate), rate: taxRate}
                lineItem.unit_tax = Calculations.totalVat(lineItem.quantity, lineItem.subtotal, taxRate)
                lineItem.unit_subtotal = Calculations.totalExcludingVat(lineItem.quantity, lineItem.subtotal)
                lineItem.unit_total = Calculations.totalIncludingVat(lineItem.quantity, lineItem.subtotal, taxRate)
                lineItem.tax = Calculations.totalVat(lineItem.quantity, lineItem.subtotal, taxRate)
                lineItem.total = Calculations.totalIncludingVat(lineItem.quantity, lineItem.subtotal, taxRate)
                this.props.onUpdated(lineItem)
              }}
            />
          </td>
          <td>
            <input
              type="number"
              className="form-control form-control-solid mb-3 mb-lg-0"
              style={{width: 100}}
              step={"0.01"}
              disabled={editable}
              value={lineItem.tax}
              onChange={e => {
                lineItem.tax = e.target.value
                lineItem.total = e.target.value + lineItem.subtotal
                this.props.onUpdated(lineItem)
              }}
            />
          </td>
          <td>
            <input
              type="number"
              className="form-control form-control-solid mb-3 mb-lg-0"
              style={{width: 100}}
              step={"0.01"}
              disabled={editable}
              value={lineItem.total}
              onChange={e => {
                lineItem.total = e.target.value
                this.props.onUpdated(lineItem)
              }}
            />
          </td>
          <td>
            <a
              className="btn btn-icon btn-sm btn-light-danger me-2 mt-1"
              onClick={e => {
                e.preventDefault()
                this.props.onDelete(lineItem)
              }}
            >
              <span className="svg-icon svg-icon-1" data-bs-toggle="tooltip"
                    data-bs-placement="top" title="" data-bs-original-title="Reject">
                <i className="fa fa-trash"></i>
              </span>
            </a>
          </td>
        </tr>
      </>
    )
  }

}
