import React from "react";
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import AuthManager from "../../../utils/AuthManager";
import General from "../../../utils/General";
import Currency from "../../../utils/Currency";

export default class Report extends React.Component {

  constructor(props) {
    super(props);

    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      show: props.show,
      record: props.record,
      message: {record: props.record.id}
    }
  }

  _reportTransaction(){
    let {
      message
    } = this.state

    if(!message.text){
      Notify.error("Please enter the reason for reporting this transaction.")
      return
    }

    this.setState({isLoading: true})

    Backend.startConversation(message).then(e => {
      Notify.success("Transaction Reported Successfully")
      this.setState({isLoading: false}, () => this.props.onReported())
    }).catch(e => {
      Notify.error(e.message)
      this.setState({isLoading: false})
    })

  }

  render() {
    let {
      show,
      record,
      message
    } = this.state

    let entity = record.entity.type === "pos_terminal" ? "POS Terminal" : `${record.entity.first_name} ${record.entity.last_name}`

    return (
      <>
        <Modal
          show={show}
          onHide={() => this.props.onHide()}
          className={''}
        >
          <Modal.Header>
            <Modal.Title>Report Transaction</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <div className="row">


              <div className="d-flex flex-column me-n7 pe-7">
                <div className="row fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <div className="col">
                    <label className="fw-semibold fs-6 mb-2">Amount</label>
                    <input
                      type="text"
                      name="user_name"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      value={Currency.format(record.currency.symbol, record.total)}
                      disabled=""
                    />
                  </div>

                  <div className="col">
                    <label className="fw-semibold fs-6 mb-2">Tax</label>
                    <input
                      type="text"
                      name="user_name"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      value={Currency.format(record.currency.symbol, record.tax)}
                      disabled=""
                    />
                  </div>

                  <div className="col">
                    <label className="fw-semibold fs-6 mb-2">Type</label>
                    <input
                      type="text"
                      name="user_name"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      value={General.toTitleCase(record.type)}
                      disabled=""
                    />
                  </div>

                </div>

                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="fw-semibold fs-6 mb-2">Customer</label>
                  <input
                    type="text"
                    name="user_name"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    value={entity}
                    disabled=""
                  />
                </div>

                <div className="fv-row mb-7 fv-plugins-icon-container" bis_skin_checked="1">
                  <label className="fw-semibold fs-6 mb-2">Subject</label>
                  <input
                    type="text"
                    name="user_name"
                    placeholder="Subject"
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    onChange={e => {
                      message.subject = e.target.value
                      this.setState({message})
                    }}
                  />
                </div>

                <div className="fv-row mb-7 fv-plugins-icon-container">
                  <label className="required fw-semibold fs-6 mb-2">Message</label>
                  <textarea
                    className="form-control form-control-solid mb-3 mb-lg-0"
                    placeholder="Type your reason for reporting this transaction here please..."
                    rows="5"
                    onChange={e => {
                      message.text = e.target.value
                      this.setState({message})
                    }}
                  />
                </div>
              </div>
            </div>

          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => this.props.onHide()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this._reportTransaction()}
              disabled={this.state.isLoading}
            >
              Report
            </button>
          </Modal.Footer>

        </Modal>
      </>
    )
  }

}
