import React from "react"

const STAT_VISUAL = require('../../../assets/media/svg/shapes/abstract-2.svg')

export default class StatCard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      icon: props.icon,
      title: props.title,
      shopify: props.shopify,
      subTitle: props.subTitle,
      lightText: props.lightText,
    }
  }

  render() {
    let {
      icon,
      title,
      shopify,
      subTitle,
      lightText,
    } = this.state

    return (
      <>
        {
          shopify &&
          <style dangerouslySetInnerHTML={{__html: `
             .st0{fill - rule:evenodd;clip-rule:evenodd;fill:#95BF47;} .st1{fill - rule:evenodd;clip-rule:evenodd;fill:#5E8E3E;} .st2{fill - rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
           `}}
          />
        }
        <div className="col-xl-3">
          <a href="#" className={`card ${this.props.background} hoverable card-xl-stretch mb-xl-8`}>
            <div className="card-body">
              <span className={`svg-icon ${this.props.iconColor} svg-icon-3x ms-n1`}>
                <svg
                  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox={`${shopify ? '0 0 108.44 122.88' : '0 0 24 24'}`}
                  style={{enableBackground: 'new 0 0 108.44 122.88'}} xmlSpace="preserve"
                >
                  {icon}
                </svg>
              </span>
              <div className={`${lightText ? 'text-gray-100' : 'text-gray-900'} fw-bolder fs-2 mb-2 mt-5`}>{title}</div>
              <div className={`fw-bold ${lightText ? 'text-gray-100' : 'text-gray-400'}`}>{subTitle}</div>
            </div>
          </a>

        </div>
      </>
    )
  }

}

StatCard.defaultProps = {
  background: "bg-body",
  iconColor: "svg-icon-primary",
  lightText: false,
}