import React from 'react'

import BaseTable from './BaseTable'

import moment from 'moment'
import Permissions from "../../../utils/Permissions";
import UploadTransactions from "../modals/UploadTransactions";
import View from "./cells/ViewCell";

export default class IngestionsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      integration: props.integration,
    }

    this.table = React.createRef()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _getColumns() {
    return [
      {
        Header: 'Date',
        id: 'created_at',
        Cell: rowInfo => {
          let ingestion = rowInfo.original
          return (
            <div className="d-flex align-items-center">
							<span className="text-dark text-hover-primary fs-7">
                {moment(ingestion.created_at).format('DD MMM YYYY')}
              </span>
            </div>
          )
        }
      },
      {
        Header: 'Retrieved',
        id: 'retrieved_no',
        Cell: rowInfo => {
          let ingestion = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <a className="text-dark fw-bolder text-hover-primary fs-6">{ingestion.retrieved_no}</a>
            </div>
          )
        }
      },
      {
        Header: 'Processed',
        id: 'processed_no',
        Cell: rowInfo => {
          let ingestion = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <a className="text-dark fw-bolder text-hover-primary fs-6">{ingestion.processed_no}</a>
            </div>
          )
        }
      },
      {
        Header: 'Succeeded',
        id: 'succeeded_no',
        Cell: rowInfo => {
          let ingestion = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <a className="text-dark fw-bolder text-hover-primary fs-6">{ingestion.succeeded_no}</a>
            </div>
          )
        }
      },
      {
        Header: 'Failed',
        id: 'failed_no',
        Cell: rowInfo => {
          let ingestion = rowInfo.original
          return (
            <div className="d-flex align-items-center">
              <a className="text-dark fw-bolder text-hover-primary fs-6">{ingestion.failed_no}</a>
            </div>
          )
        }
      },
      {
        Header: 'Status',
        id: 'status',
        Cell: rowInfo => {
          let ingestion = rowInfo.original
          let className = "badge-light-dark"
          if(ingestion.status === "processing"){
            className = 'badge-light-info'
          }else if(ingestion.status === "complete"){
            className = 'badge-light-success'
          }
          return (
            <span className={`badge ${className} badge-sm fs-base m-lg-auto`}>
							{ ingestion.status.toUpperCase() }
						</span>
          )
        }
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let ingestion = rowInfo.original
          if(!ingestion.error_file) return null
          return (
            <div className={"my-auto float-end"}>
              <View
                tooltip={"View Errors"}
                onViewClicked={() => {
                  window.open(`${ingestion.error_file.url}`, '_blank')
                }}
              />
            </div>
          )
        }
      }
    ]
  }

  _renderToolBar(){
    let { integration } = this.state

    if(integration.type !== "amazon_file"){
      return null
    }

    return (
      <>
        {
          Permissions.hasCreatePermission(Permissions.INTEGRATIONS) &&
          <a
            className="btn btn-primary ms-5 font-weight-bolder"
            onClick={() => {
              this.setState({
                showTransactionsModal: true,
              })
            }}
            style={{ height: "45px" }}
          >
            <span className="svg-icon svg-icon-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect
                    fill="#000000"
                    x="4"
                    y="11"
                    width="16"
                    height="2"
                    rx="1"
                  ></rect>
                  <rect
                    fill="#000000"
                    opacity="0.3"
                    transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                    x="4"
                    y="11"
                    width="16"
                    height="2"
                    rx="1"
                  ></rect>
                </g>
              </svg>
            </span>
            Upload TAX Report
          </a>
        }
      </>
    )
  }

  render() {
    let { integration, showTransactionsModal } = this.state
    const columns = this._getColumns()

    if(!integration) return null

    let title = "Ingestions"

    if(integration.type === "amazon_file"){
      title = "TAX Reports"
    }

    return (
      <div>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.Ingestions}?integration_id=${integration.id}${integration.type === "amazon_file" ? '' : '&min_retrieved_no=1'}`}
          noDataMessage={'No ingestions found'}
          title={title}
          columns={columns}
          showExport={false}
          showFilter={false}
          defaultSorted={[
            {
              id: 'created_at',
              desc: true,
            },
          ]}
          showSearch={!this.props.latestResults}
          showPaginationBottom={!this.props.latestResults}
          renderToolbar={() => this._renderToolBar()}
        />
        <UploadTransactions
          show={showTransactionsModal}
          integration={integration}
          onHide={() => {
            this.setState({showTransactionsModal: false})
          }}
          onSuccess={() => {
            this.table.current.refresh()
            this.setState({showTransactionsModal: false})
          }}
        />
      </div>
    )
  }
}

IngestionsTable.defaultProps = {
  latestResults: false,
}
