import Currency from "./Currency";
import General from "./General";

export default class Calculations {

  static lineItemTotal(priceExcludingVat, vat){
    let value = parseFloat(priceExcludingVat) + (parseFloat(priceExcludingVat) * vat)
    return !value ? '0.00' : Currency.format('', value)
  }

  static totalExcludingVat(quantity, priceExcludingVat){
    let value = (quantity * (priceExcludingVat))
    return !value ? '0.00' : value.toFixed(2)
  }

  static totalVat(quantity, priceExcludingVat, vat){
    let value = (quantity * ((priceExcludingVat) * vat))
    return !value ? '0.00' : value.toFixed(2)
  }

  static totalIncludingVat(quantity, priceExcludingVat, vat){
    let value = parseFloat(Calculations.totalExcludingVat(quantity, priceExcludingVat)) + parseFloat(Calculations.totalVat(quantity, priceExcludingVat, vat))
    return !value ? '0.00' : value.toFixed(2)
  }

  static itemsSubTotal(items){
    let subTotal = 0;
    items.map(item => {
      subTotal += parseFloat(Calculations.totalExcludingVat(item.quantity, item.unit_subtotal))
    })
    return !subTotal ? '0.00' : subTotal
  }

  static itemsVatTotal(items){
    let vat = 0;
    items.map(item => {
      let itemVat = item.taxes.length > 0 ? parseFloat(item.taxes[0].rate) : 0
      vat += parseFloat(Calculations.totalVat(item.quantity, item.unit_subtotal, itemVat))
    })
    return !vat ? '0.00' : vat
  }

  static itemsTotal(items, vat){
    let total = 0;
    items.map(item => {
      total += parseFloat(Calculations.totalExcludingVat(item.quantity, item.unit_subtotal))
    })
    total += vat ? parseFloat(vat) : 0
    return !total ? '0.00' : total
  }

}
