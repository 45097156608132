import React, { Component } from "react";

import moment from "moment";
import General from "../../../utils/General";

export class ConversationListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      conversation: props.conversation,
    };
  }

  _renderConversationInfo(conversation) {
    let lastMessageText = conversation.last_message?.text.slice(0, 100) || "";

    let title = '';

    if(conversation.document){
      title = 'Document Report'
    }else if(conversation.record){
      title = 'Transaction Report'
    }

    if(conversation.subject){
      title = conversation.subject;
    }

    return (
      <>
        <div
          className={`d-flex flex-stack py-4 mb-2 px-3 conversation-item ${this.props.selected  && "bg-light-dark"}`}
          style={{cursor: 'pointer'}}
          onClick={() => this.props.onClick(conversation)}
        >
          <div className="d-flex align-items-center" style={{maxWidth: 200}}>
            <div className="">
              <a href="#" className={`fs-5 ${this.props.selected  && "fw-bolder"} text-gray-900 text-hover-primary mb-2 subject`}>
                {title}
              </a>
              <div className="fw-bold text-muted subject">
                {lastMessageText}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column ">
            <span className={`badge badge-light-${conversation.status === "open" ? 'success' : 'danger'} badge-sm fs-base m-lg-auto float-end`}>
              {General.toTitleCase(conversation.status)}
            </span>
            <span className="text-muted fs-7 mb-1">
              {conversation.last_message && moment(conversation.last_message.created_at).fromNow()}
            </span>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { conversation } = this.state;

    return this._renderConversationInfo(conversation);
  }
}

export default ConversationListItem;
