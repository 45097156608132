import React from 'react'

import AuthManager from '../utils/AuthManager'

import ReportsTable from "./components/tables/Reports";

export default class Reports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <>
        <ReportsTable title={'Reports'} />
      </>
    )
  }
}
