import React from "react"

import { Link, withRouter } from "react-router-dom";
import moment from "moment";

import UserModal from '../modals/User'

import BaseTable from "./BaseTable"
import General from "../../../utils/General";
import EditCell from "./cells/EditCell";
import DeleteCell from "./cells/DeleteCell";
import AuthManager from "../../../utils/AuthManager";
import ConfirmationModal from "../modals/Confirmation";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import Permissions from "../../../utils/Permissions";

class TeamMembers extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }

    this.table = React.createRef()
  }

  _getColumns(){
    let columns = [
      {
        Header: 'User',
        id: 'user.first_name',
        Cell: rowInfo => {
          let teamMember = rowInfo.original
          let user = teamMember.user
          let title = user.first_name + " " + user.last_name
          let subtitle = user.email

          return (
            <div class="d-flex align-items-center">
              <div class="d-flex justify-content-start flex-column">
                <a class="text-dark fw-bolder text-hover-primary fs-6">{title}</a>
                <span class="text-muted fw-bold text-muted d-block fs-7">{subtitle}</span>
              </div>
            </div>
          )
        }
      },

      {
        Header: 'Role',
        id: 'role.name',
        accessor: teamMember => teamMember.role.name,
      },
      {
        Header: 'Date Added',
        id: 'created_at',
        accessor: teamMember => moment(teamMember.created_at).format('DD MMM YYYY'),
        type: "date",
        format: "%d %b %Y"
      },
      {
        Header: '',
        id: '',
        sortable: false,
        exportable: false,
        Cell: rowInfo => {
          let teamMember = rowInfo.original
          let isCurrentUser = teamMember.user.id === AuthManager.currentUser.user.id

          let isAdmin = AuthManager.currentUser.role?.admin

          let canEdit = !isCurrentUser && isAdmin
          let canDelete = !isCurrentUser && isAdmin

          return (
            <div className={"my-auto float-end"}>
              <>
                {
                  (Permissions.hasEditPermission(Permissions.TEAM_MEMBERS) && canEdit) &&
                  <EditCell
                    tooltip={"Edit"}
                    onEditClicked={() => {
                      this.setState({
                        showTeamMemberModal: true,
                        teamMember,
                      })
                    }}
                  />
                }
                {
                  (Permissions.hasDeletePermission(Permissions.TEAM_MEMBERS) && canDelete) &&
                  <DeleteCell
                    tooltip={"Delete"}
                    iconClass={"la-trash"}
                    onDeleteClicked={() => {
                      this.setState({
                        showConfirmationModal: true,
                        teamMember,
                      })
                    }}
                  />
                }
              </>
            </div>
          )
        }
      }
    ]

    return columns
  }

  _deleteTeamMember(){
    let {
      teamMember,
    } = this.state

    this.setState({loading: true})

    Backend.deleteTeamMember(teamMember).then(() => {
      Notify.success('Member has been deleted successfully')
      this.setState({
        teamMember: null,
        loading: false,
        showConfirmationModal: false
      })
      this.table.current.refresh()
    }).catch(e => {
      Notify.error(e.message)
      this.setState({
        teamMember: null,
        loading: false,
        showConfirmationModal: false,
      })
    })
  }

  _renderToolBar() {
    return (
      <>
        {
          Permissions.hasCreatePermission(Permissions.TEAM_MEMBERS) &&
          <a
            className="btn btn-primary ms-5 font-weight-bolder"
            onClick={() => {
              this.setState({
                showTeamMemberModal: true,
              })
            }}
            style={{ height: "45px" }}
          >
          <span className="svg-icon svg-icon-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect
                  fill="#000000"
                  x="4"
                  y="11"
                  width="16"
                  height="2"
                  rx="1"
                ></rect>
                <rect
                  fill="#000000"
                  opacity="0.3"
                  transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                  x="4"
                  y="11"
                  width="16"
                  height="2"
                  rx="1"
                ></rect>
              </g>
            </svg>
          </span>
            Add Team Member
          </a>
        }
      </>
    )
  }

  _renderTeamMemberModal(){
    let { teamMember, showTeamMemberModal } = this.state

    return (
      <UserModal
        show={showTeamMemberModal}
        teamMember={General.clone(teamMember)}
        onHide={() => this.setState({ showTeamMemberModal: false, teamMember: null })}
        onAdded={(member) => {
          this.table.current.refresh()
          this.setState({ showTeamMemberModal: false, teamMember: null })
        }}
      />
    )
  }

  _renderConfirmationModal(){
    let { showConfirmationModal } = this.state

    return (
      <ConfirmationModal
        show={showConfirmationModal}
        title={"Are you sure?"}
        body={" This action can't be undone."}
        confirmTitleText={"Delete"}
        deleteTheme={"danger"}
        loading={this.state.loading}
        onConfirm={() => this._deleteTeamMember()}
        onHide={() => this.setState({ showConfirmationModal: false, teamMember: null })}
      />
    )
  }

  render() {
    let {

    } = this.state

    const columns = this._getColumns()

    return (
      <>
        <BaseTable
          ref={this.table}
          endpoint={`${window.Api.CompanyMembers}`}
          showFilter={false}
          noDataMessage={"No team members found"}
          title={"Team"}
          columns={columns}
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          renderToolbar={() => this._renderToolBar()}
        />
        {this._renderTeamMemberModal()}
        {this._renderConfirmationModal()}
      </>
    )

  }

}

export default withRouter(TeamMembers);
